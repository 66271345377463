import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { InfiniteScroll, NoData } from '@meronex/components';

import TransactionItem from './TransactionItem';
import TransactionDialog from './TransctionDialog';
import { can, p } from '../../../../common/roles';

const useStyles = makeStyles((theme) => ({
  container: {},
  title: {
    color: 'gray',
    marginTop: '10px',
    marginBottom: '10px',
    textTransform: 'upperCase',
    fontSize: '12px',
  },
}));

const Transactions = React.forwardRef((props, ref) => {
  const {
    onReverseTransaction,
    isScrolling,
    fetchTransactions,
    height,
    itemStyle,
    isStoreTransactions,
    permissions,
  } = props;
  const classes = useStyles();

  const infiniteScrollRef = React.useRef(null);

  const [openTransactionDialog, setOpenTransactionDialog] = React.useState();
  const [selectedTransaction, setSelectedTransaction] = React.useState();

  React.useImperativeHandle(ref, () => ({
    refresh: () => {
      refreshTransaction();
    },
  }));

  const refreshTransaction = () => {
    infiniteScrollRef.current.refreshList();
  };

  const _itemStyle = Object.assign(
    {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
    itemStyle
  );

  return (
    <div>
      <InfiniteScroll
        ref={infiniteScrollRef}
        height={height}
        renderItem={(data, isScrolling, index) => {
          return (
            <TransactionItem
              showPrice={can(p.globals.viewPrices)}
              style={_itemStyle}
              item={data}
              isStoreTransaction={isStoreTransactions}
              index={index}
              onClick={() => {
                setSelectedTransaction(data);
                setOpenTransactionDialog(true);
              }}
            />
          );
        }}
        itemHeight={isStoreTransactions ? 155 : 115}
        lastItemHeight={150}
        onScroll={isScrolling}
        noRowRenderer={() => {
          return (
            <div>
              <NoData message={'No Transactions Available'} type={'warn'} />
            </div>
          );
        }}
        getListItems={fetchTransactions}
      />
      {selectedTransaction && (
        <TransactionDialog
          transaction={selectedTransaction}
          open={openTransactionDialog}
          permissions={permissions}
          onReverse={() => {
            console.log('on reverse');
            refreshTransaction();
            if (onReverseTransaction) {
              onReverseTransaction();
            }
          }}
          onClose={() => {
            setOpenTransactionDialog(false);
          }}
        />
      )}
    </div>
  );
});

Transactions.propTypes = {
  onReverseTransaction: PropTypes.func,
  fetchTransactions: PropTypes.func,
  height: PropTypes.number,
  isStoreTransactions: PropTypes.bool,
};

Transactions.defaultProps = {
  height: 500,
  isStoreTransactions: false,
};
export default Transactions;
