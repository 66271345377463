/**
 *  Load more items, basically appending to the end of the list until end is reached (has more is false)
 */
export const _loadMore = async ({
  items,
  skip = 0,
  limit = 9,
  getListItems,
}) => {
  let _items = skip !== 0 && items ? items : [];

  console.log(`[EndlessScroll]: load more items skip=${skip},limit=${limit},`);
  let fetchedItems = await getListItems({
    skip,
    limit: 9,
  });
  if (!fetchedItems) {
    fetchedItems = [];
  }
  _items = [..._items, ...fetchedItems];
  return {
    _items,
    hasMore: fetchedItems && fetchedItems.length >= limit,
  };
};

/**
 *  LReload a range
 */
export const _reloadRange = async ({
  getListItems,
  startIndex,
  endIndex,
  items,
}) => {
  console.log(
    `[EndlessScroll]: refresh list range, start ${startIndex}, end: ${endIndex}`
  );

  // slice until the start index to maintain
  // the values until the there
  const skip = startIndex;
  const limit = endIndex - startIndex;

  console.log(`fetching skip: ${skip}, limit:${limit}`);
  const fetchedItems = await getListItems({
    skip,
    limit,
  });
  console.log(fetchedItems);
  let _items = [];
  if (items && fetchedItems) {
    items.forEach((item, index) => {
      console.log(index);
      if (startIndex <= index && index <= endIndex) {
        console.log(`pushing fetched index: ${index - startIndex}`);
        console.log(fetchedItems[index - startIndex]);
        _items.push(fetchedItems[index - startIndex]);
      } else {
        _items.push(item);
      }
    });
  }
  console.log(_items);
  return _items;
};

export const _reloadItem = async ({
  selectedItemIndex,
  items,
  getListItem,
}) => {
  console.log(`[_reloadItems]': ${selectedItemIndex}`);
  console.log(items);
  console.log(getListItem);
  const fetchedItem = await getListItem(items[selectedItemIndex]._id);
  console.log(fetchedItem);
  fetchedItem.isModified = true;
  items[selectedItemIndex] = fetchedItem;
  return items;
};
