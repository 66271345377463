import React from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import { App } from '@meronex/app';
import { NoData } from '@meronex/components';
import View from '../views/components/View';
import { Icons } from '../common/Icons';
import { can, p } from '../common/roles';

import RolesDialog from './roles/components/RolesDialog';
import MembersDialog from './members/components/MembersDialog';
import StoreItems from './storeItems/SettingsItemTypesDialog';
import ItemCategoriesDialog from './itemCategories/components/ItemCategoriesDialog';
import StoreCategoriesDialog from './storeCategories/components/StoreCategoriesDialog';

import SettingsItemTypesDialog from './storeItems/SettingsItemTypesDialog';
import TeamsDialog from './teams/components/TeamsDialog';

function SettingsView(props) {
  const { activeView } = props;

  const [openRolesDialog, setOpenRolesDialog] = React.useState();
  const [openMembersDialog, setOpenMembersDialog] = React.useState();
  const [openAllStoreItems, setOpenAllStoreItems] = React.useState();
  const [openItemCategories, setOpenItemCategories] = React.useState();
  const [openTeamsDialog, setOpenTeamsDialog] = React.useState();
  const [
    openStoreCategoriesDialog,
    setOpenStoreCategoriesDialog,
  ] = React.useState();

  const sectionTitleStyle = {
    fontWeight: '500',
    marginBottom: '10px',
    fontSize: '18px',
    marginLeft: '16px',
    paddingTop: '15px',
  };

  const dividerStyle = {
    height: '12px',
    backgroundColor: 'whitesmoke',
  };

  const itemStyle = {
    borderBottom: '1px solid whitesmoke',
    paddingTop: '10px',
    paddingBottom: '10px',
    fontSize: '15px',
  };

  const lastItemStyle = { ...itemStyle };
  lastItemStyle.borderBottom = 'none';

  const iconStyle = {
    size: 20,
    marginRight: '10px',
  };
  return (
    <View
      viewId={'settings'}
      activeView={activeView}
      showMenu
      icon={<Icons.Settings size={20} />}
      title={'Settings'}>
      <div style={{ marginTop: '10px', overflow: 'auto' }}>
        {can(p.globals.viewSettings) && (
          <List>
            <Divider style={dividerStyle} />
            <div style={sectionTitleStyle}>
              {' '}
              <Icons.Members
                size={iconStyle.size}
                style={{
                  marginRight: '5px',
                  position: 'relative',
                  top: '4px',
                }}
              />{' '}
              Users Management
            </div>
            <ListItem
              style={itemStyle}
              button
              onClick={() => {
                setOpenMembersDialog(true);
              }}>
              Members
            </ListItem>
            <ListItem
              style={itemStyle}
              button
              onClick={() => {
                setOpenRolesDialog(true);
              }}>
              Roles
            </ListItem>
            <ListItem
              style={itemStyle}
              button
              onClick={() => {
                setOpenTeamsDialog(true);
              }}>
              Teams
            </ListItem>
            <Divider style={dividerStyle} />
            <div
              style={{
                marginTop: '10px',
              }}>
              <div style={sectionTitleStyle}>
                <Icons.Data
                  size={iconStyle.size}
                  style={{
                    marginRight: '5px',
                    position: 'relative',
                    top: '4px',
                  }}
                />{' '}
                Data Master
              </div>
              <ListItem
                button
                style={itemStyle}
                onClick={() => {
                  setOpenAllStoreItems(true);
                }}>
                Item Types
              </ListItem>
              <ListItem
                button
                style={itemStyle}
                onClick={() => {
                  setOpenItemCategories(true);
                }}>
                Item Categories
              </ListItem>
              <ListItem
                button
                style={itemStyle}
                onClick={() => {
                  setOpenStoreCategoriesDialog(true);
                }}>
                Store Categories
              </ListItem>
              {/*<ListItem*/}
              {/*  button*/}
              {/*  style={itemStyle}*/}
              {/*  onClick={() => {*/}
              {/*    // setOpenItemCategories(true);*/}
              {/*    setOpenProducts(true);*/}
              {/*  }}>*/}
              {/*  All Products*/}
              {/*</ListItem>*/}
              {/*<ListItem button style={itemStyle}>*/}
              {/*  Item Categories*/}
              {/*</ListItem>*/}
              {/*<ListItem*/}
              {/*  button*/}
              {/*  style={{*/}
              {/*    paddingTop: '10px',*/}
              {/*    paddingBottom: '10px',*/}
              {/*    fontSize: '15px',*/}
              {/*  }}>*/}
              {/*  Product Types*/}
              {/*</ListItem>*/}
            </div>
            {/*<Divider style={dividerStyle} />*/}
            {/*<div*/}
            {/*  style={{*/}
            {/*    marginTop: '10px',*/}
            {/*  }}>*/}
            {/*  <div style={sectionTitleStyle}>*/}
            {/*    <Icons.Partners*/}
            {/*      size={iconStyle.size}*/}
            {/*      style={{*/}
            {/*        marginRight: '5px',*/}
            {/*        position: 'relative',*/}
            {/*        top: '4px',*/}
            {/*      }}*/}
            {/*    />{' '}*/}
            {/*    Partners*/}
            {/*  </div>*/}
            {/*  <ListItem button style={itemStyle}>*/}
            {/*    Vendors*/}
            {/*  </ListItem>*/}
            {/*  <ListItem button style={lastItemStyle}>*/}
            {/*    Suppliers*/}
            {/*  </ListItem>*/}
            {/*</div>*/}
          </List>
        )}
        {!can(p.globals.viewSettings) && (
          <div>
            <NoData type={'warn'} message={`No user settings defined`} />
          </div>
        )}
      </div>
      <div>
        {openRolesDialog && (
          <RolesDialog
            open={openRolesDialog}
            onClose={() => {
              setOpenRolesDialog(false);
            }}
          />
        )}
        {openMembersDialog && (
          <MembersDialog
            open={openMembersDialog}
            onClose={() => {
              setOpenMembersDialog(false);
            }}
          />
        )}
        {openAllStoreItems && (
          <SettingsItemTypesDialog
            open={openAllStoreItems}
            onClose={() => {
              setOpenAllStoreItems(false);
            }}
          />
        )}
        {openItemCategories && (
          <ItemCategoriesDialog
            open={openItemCategories}
            onClose={() => {
              setOpenItemCategories();
            }}
          />
        )}
        {openTeamsDialog && (
          <TeamsDialog
            open={openTeamsDialog}
            onClose={() => {
              setOpenTeamsDialog(false);
            }}
          />
        )}
        {openStoreCategoriesDialog && (
          <StoreCategoriesDialog
            open={openStoreCategoriesDialog}
            onClose={() => {
              setOpenStoreCategoriesDialog(false);
            }}
          />
        )}
        {/*{openProducts && (*/}
        {/*  <ProductsDialog*/}
        {/*    open={openProducts}*/}
        {/*    onClose={() => {*/}
        {/*      setOpenProducts(false);*/}
        {/*    }}*/}
        {/*  />*/}
        {/*)}*/}
      </div>
    </View>
  );
}
SettingsView.propTypes = {
  activeView: PropTypes.string,
};

SettingsView.defaultProps = {};
export default SettingsView;
