import React from 'react';
import PropTypes from 'prop-types';

import { NoData, AlertDialog, toast, Loading } from '@meronex/components';

import List from '@material-ui/core/List';

import MemberItem from './MemberItem';
import { membersMng } from '../api/membersMng';

function MembersList(props) {
  const { members, onClick, onEdit, onDelete } = props;

  const [deleting, setDeleting] = React.useState();

  const [openDeleteAlert, setOpenDeleteAlert] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState();

  const _onDelete = (member) => {
    setOpenDeleteAlert(true);
    setSelectedItem(member);
  };

  const onConfirmDelete = async () => {
    setDeleting(true);
    setOpenDeleteAlert(false);
    await membersMng.deleteMember(selectedItem);
    toast.success('Member successfully deleted');
    setDeleting(false);
    onDelete();
  };

  const renderMembers = () => {
    if (!Array.isArray(members)) {
      return <Loading />;
    } else if (members.length === 0) {
      return <NoData type={'warn'} />;
    }

    return members.map((member) => {
      return (
        <MemberItem
          member={member}
          onEdit={onEdit}
          onDelete={_onDelete}
          onClick={onClick}
        />
      );
    });
  };

  return (
    <>
      <List>{renderMembers()}</List>
      <AlertDialog
        open={openDeleteAlert}
        loading={deleting}
        onClose={() => {
          setOpenDeleteAlert(false);
        }}
        onConfirm={onConfirmDelete}
      />
    </>
  );
}
MembersList.propTypes = {
  members: PropTypes.array,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
};

MembersList.defaultProps = {
  onEdit: () => {
    console.log('on edit');
  },
  onDelete: () => {
    console.log('on delete');
  },
  onClick: () => {
    console.log('on click');
  },
};
export default MembersList;
