import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import { useTheme } from '@material-ui/core';

export default function MenuTile(props) {
  const theme = useTheme();

  return (
    <Paper
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
      style={{
        cursor: 'pointer',
        width: '46%',
        margin: '5px',
        backgroundColor: props.bgColor,
        color: theme.appTheme.tilesFont,
        fontSize: '25px',
        padding: '5px',
      }}>
      <div>
        <div style={{ fontSize: '50px', marginTop: '30px' }}>{props.icon}</div>
        <div
          style={{
            fontSize: '18px',
          }}>
          {props.title}
        </div>
      </div>
    </Paper>
  );
}

MenuTile.defaultProps = {
  bgColor: '#008000ab',
};

MenuTile.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  bgColor: PropTypes.string,
  onClick: PropTypes.func,
};
