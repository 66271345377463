import React from 'react';
import PropTypes from 'prop-types';
import { Form, ImageUploader } from '@meronex/components';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import InputAdornment from '@material-ui/core/InputAdornment';

import ItemCategoriesSelect from '../../../settings/itemCategories/components/ItemCategoriesSelect';
import { MenuItem } from '@material-ui/core';

const ItemTypeForm = React.forwardRef((props, ref) => {
  const { onUpdate, itemType, validateOnInit } = props;

  const previewContainerRef = React.useRef();
  const validatedForm = React.useRef();

  const [imgUrl, setImgUrl] = React.useState(null);
  const [thumbUrl, setThumbUrl] = React.useState(undefined);
  const [uploading, setUploading] = React.useState(false);
  const [formData, setFormData] = React.useState();
  const [category, setCategory] = React.useState(itemType.category);
  const [unit, setUnit] = React.useState('unit');

  const [categories, setCategories] = React.useState();

  React.useEffect(() => {
    if (!Array.isArray(categories)) {
      setCategories(categories);
    }
  }, []);
  React.useEffect(() => {
    console.log(formData);
    if (formData) {
      if (imgUrl) {
        formData.values.image = {
          imgUrl,
          thumbUrl,
        };
      }

      formData.values.price = Number.parseFloat(formData.values.price) || 0;
      formData.values.category = category;
      formData.uploading = uploading;
      console.log(formData);
      onUpdate(formData);
    }
  }, [formData, uploading, imgUrl, category]);

  React.useEffect(() => {
    if (itemType && itemType.image && !imgUrl) {
      setImgUrl(itemType.image.imgUrl);
      setThumbUrl(itemType.image.thumbUrl);
    }
  }, [itemType]);
  React.useImperativeHandle(ref, () => ({
    reset: () => {
      validatedForm.current.reset();
    },
  }));

  console.log(validateOnInit);
  return (
    <>
      <Form
        validateOnInit={validateOnInit}
        ref={validatedForm}
        onUpdate={(_formData) => {
          console.log(_formData);
          if (_formData.values.unit) {
            setUnit(_formData.values.unit);
          }
          setFormData(_formData);
        }}>
        <TextField
          name={'name'}
          label="Name"
          defaultValue={itemType.name}
          validate={(v) => {
            if (!v || v === '') return 'Name is required';
            return true;
          }}
          fullWidth
          multiline
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <ItemCategoriesSelect
          value={category}
          onChange={(v) => {
            setCategory(v);
          }}
        />
        {/*<TextField*/}
        {/*  name={'category'}*/}
        {/*  defaultValue={itemType.category}*/}
        {/*  label="Category"*/}
        {/*  style={{ margin: 8 }}*/}
        {/*  validate={(v) => {*/}
        {/*    if (!v || v === '') return 'Category is required';*/}
        {/*    return true;*/}
        {/*  }}*/}
        {/*  fullWidth*/}
        {/*  multiline*/}
        {/*  margin="normal"*/}
        {/*  InputLabelProps={{*/}
        {/*    shrink: true,*/}
        {/*  }}*/}
        {/*/>*/}
        <TextField
          name={'code'}
          defaultValue={itemType.code}
          label="Code"
          fullWidth
          multiline
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          name={'unit'}
          label="Unit"
          defaultValue={itemType.unit}
          fullWidth
          placeholder={'unit of measurements'}
          multiline
          validate={(v) => {
            if (!v || v === '') return 'Unit is required';
            return true;
          }}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          name={'price'}
          key={itemType.unit}
          label="Price"
          defaultValue={itemType.price}
          fullWidth
          validate={(v) => {
            const isNan = Number.isNaN(parseFloat(v));
            const unit = itemType.unit;
            if (isNan) {
              setUnit(unit);
              return 'Price  must be a number';
            }
            setUnit(unit);
            return true;
          }}
          margin="normal"
          type={'number'}
          placeholder={`price per ${unit}`}
          helperText={'price per unit'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">AED/{unit}</InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          name={'description'}
          label="Description "
          defaultValue={itemType.description}
          fullWidth
          multiline
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Form>
      <div style={{ marginTop: '20px', width: '100%', textAlign: 'center' }}>
        <ImageUploader
          defaultPreviewUrl={
            itemType && itemType.image ? itemType.image.imgUrl : null
          }
          previewContainer={previewContainerRef}
          onUploadStart={() => {
            setUploading(true);
          }}
          onUploadSuccess={(imageData) => {
            console.log(imageData);
            setImgUrl(imageData.imgUrl);
            setThumbUrl(imageData.thumbUrl);
            setUploading(false);
          }}
        />
        <div
          ref={previewContainerRef}
          style={{ maxWidth: '400px', textAlign: 'center', margin: 'auto' }}
        />
      </div>
    </>
  );
});
ItemTypeForm.propTypes = {
  onUpdate: PropTypes.func,
  validateOnInit: PropTypes.bool,
};

ItemTypeForm.defaultProps = {
  onUpdate: () => {},
  itemType: {},
  validateOnInit: false,
};
export default ItemTypeForm;
