import React from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import CgNotes from '@meronex/icons/cg/CgNotes';

import Switch from '@material-ui/core/Switch';

import { useHistory } from 'react-router-dom';

import GoSignOut from '@meronex/icons/go/GoSignOut';
import GoPerson from '@meronex/icons/go/GoPerson';

import IconButton from '@material-ui/core/IconButton';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import IPassword from '@meronex/icons/ri/RiLockPasswordLine';

import ProfileDialog from './profile/ProfileDialog';
import PasswordDialog from './profile/PasswordDialog';

import { AppContext } from '../../base/AppContext';
import Changelog from '../../components/core/Changelog';

export default function Settings(props) {
  const { open } = props;

  const context = React.useContext(AppContext);
  const { App } = context;

  const [community, setCommunity] = React.useState();
  const [openChangelog, setOpenChangelog] = React.useState();
  const [themeType, setThemeType] = React.useState(App.themeType !== 'light');
  const [openProfile, setOpenProfile] = React.useState(false);
  const fetchUser = () => {
    if (!App.opts.disableUserAccount && !App.opts.disableServer) {
      const user = App.getUser();
      return user;
    }
    return null;
  };
  const [user, setUser] = React.useState(fetchUser());
  const [openPasswordDialog, setOpenPasswordDialog] = React.useState();

  const history = useHistory();

  const fetchCommunity = async () => {
    const community = await App.getSessionCommunity();
    setCommunity(community);
    console.log(community);
  };
  React.useEffect(() => {
    if (open && user) {
      fetchCommunity();
    }
  }, [open]);
  const theme = useTheme();

  if (!user && !App.opts.disableUserAccount && !App.opts.disableServer) {
    history.push('login');
    return null;
  }

  const itemStyle = {
    fontSize: '16px',
    display: 'flex',
  };

  const handleLogout = () => {
    if (context.server && context.server.connected) {
      context.server.logout();
    }
    App.clientSettings.removeItem('token');
    App.clientSettings.removeItem('userId');
    App.clientSettings.removeItem('loginInfo');
    history.push('login');
  };

  const handleUpdateProfile = async () => {
    const user = await App.fetchUser();
    setUser(user);
  };

  const toggleTheme = () => {
    setThemeType(!themeType);
    App.themeManager.changeTheme(themeType ? 'light' : 'dark');
  };

  console.log(openChangelog);
  return (
    <div>
      <List
        dense={false}
        style={{
          paddingBottom: '0px',
        }}>
        {!App.opts.disableUserAccount && (
          <>
            <ListItem
              onClick={() => {
                setOpenProfile(true);
              }}>
              <ListItemIcon>
                <GoPerson size={20} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <div style={itemStyle}>
                    <div
                      style={{
                        position: 'relative',
                        top: '4px',
                        marginRight: '10px',
                      }}>
                      Edit Profile
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        position: 'absolute',
                        right: '26px',
                        top: '4px',
                      }}>
                      <Avatar
                        style={{
                          width: '35px',
                          height: '35px',
                        }}
                        alt={App.utils.getUserName(user)}
                        src={user.profile.avatar}
                      />
                    </div>
                  </div>
                }
              />
            </ListItem>
            <Divider />
          </>
        )}
        <ListItem>
          <ListItemIcon
            style={{
              position: 'relative',
              left: '-14px',
            }}>
            <IconButton
              color="inherit"
              onClick={() => {
                toggleTheme();
              }}
              data-ga-event-category="header"
              data-ga-event-action="dark">
              {themeType ? <Brightness4Icon /> : <Brightness7Icon />}
            </IconButton>
          </ListItemIcon>
          <ListItemText
            primary={
              <span style={itemStyle}>
                <div
                  style={{
                    position: 'relative',
                  }}>
                  Theme
                </div>
                <div
                  style={{
                    display: 'flex',
                    position: 'absolute',
                    right: '26px',
                  }}>
                  <div>Light</div>
                  <div
                    style={{
                      position: 'relative',
                      top: '-8px',
                    }}>
                    <Switch
                      checked={themeType}
                      onChange={() => toggleTheme()}
                      name="themToggle"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </div>
                  <div
                    style={{
                      position: 'relative',
                      color: '#f40b56',
                    }}>
                    Dark
                  </div>
                </div>
              </span>
            }
          />
        </ListItem>
        <Divider />
        <ListItem
          onClick={() => {
            setOpenChangelog(true);
          }}>
          <ListItemIcon
            style={{
              position: 'relative',
              left: '-14px',
            }}>
            <IconButton
              color="inherit"
              onClick={() => {
                toggleTheme();
              }}
              data-ga-event-category="header"
              data-ga-event-action="dark">
              {themeType ? <CgNotes /> : <CgNotes />}
            </IconButton>
          </ListItemIcon>
          <ListItemText
            primary={
              <span style={itemStyle}>
                <div
                  style={{
                    position: 'relative',
                  }}>
                  Change Log
                </div>
              </span>
            }
          />
        </ListItem>
        <Changelog
          open={openChangelog}
          onClose={() => {
            setOpenChangelog(false);
          }}
        />
        <Divider />
        {/*<ListItem*/}
        {/*  onClick={() => {*/}
        {/*    App.eventsManager.emit('event-open-changelog', Settings.name);*/}
        {/*  }}>*/}
        {/*  <ListItemIcon>*/}
        {/*    <GoChecklist size={20} />*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText primary={<span style={itemStyle}>Changelog</span>} />*/}
        {/*</ListItem>*/}
        {/*<Divider />*/}
        {/*<Divider />*/}
        {/*<ListItem>*/}
        {/*  <ListItemIcon>*/}
        {/*    <MdFeedback size={20} />*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText*/}
        {/*    primary={<span style={itemStyle}>Help and Feedback</span>}*/}
        {/*  />*/}
        {/*</ListItem>*/}
        {/*<Divider />*/}
        <ListItem
          onClick={() => {
            setOpenPasswordDialog(true);
          }}>
          <ListItemIcon>
            <IPassword size={20} />
          </ListItemIcon>
          <ListItemText
            primary={<span style={itemStyle}>Change Password</span>}
          />
        </ListItem>
        <Divider />
        {!App.opts.disableUserAccount && (
          <>
            <ListItem
              onClick={() => {
                handleLogout();
              }}>
              <ListItemIcon>
                <GoSignOut size={20} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <div>
                    <div
                      style={{
                        fontSize: '16px',
                      }}>
                      <span>Sign Out </span>
                      {community && (
                        <span
                          style={{
                            float: 'right',
                            color: 'rgba(0, 0, 0, 0.54)',
                            fontSize: '0.875rem',
                          }}>
                          {community.name}
                        </span>
                      )}
                    </div>
                  </div>
                }
                secondary={<div>Signed in as {user.emails[0].address}</div>}
              />
            </ListItem>
            <Divider />
          </>
        )}
      </List>
      {!App.opts.disableServer && !App.opts.disableUserAccount && (
        <ProfileDialog
          user={user}
          onUpdate={handleUpdateProfile}
          open={openProfile}
          onClose={() => {
            setOpenProfile(false);
          }}
        />
      )}
      <PasswordDialog
        open={openPasswordDialog}
        onClose={() => {
          setOpenPasswordDialog(false);
        }}
      />
    </div>
  );
}
