import React from 'react';
import PropTypes from 'prop-types';

import { useReactToPrint } from 'react-to-print';

import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid/Grid';
import IconButton from '@material-ui/core/IconButton/IconButton';
import ICelsius from '@meronex/icons/mdc/MdcTemperatureCelsius';

import {
  ActionBtn,
  CloseBtn,
  SmartDrawer,
  AlertDialog,
  toast,
} from '@meronex/components';

import IWorkOrder from '@meronex/icons/fa/FaUsersCog';
import IStore from '@meronex/icons/mdc/MdcWarehouse';
import IReverse from '@meronex/icons/fa/FaUndo';
import IPrint from '@meronex/icons/fd/FdPrint';
import ITemp from '@meronex/icons/fa/FaTemperatureLow';

import { p, can } from '../../../../common/roles';
import Quantity from '../../../../common/Quantity';
import { Icons } from '../../../../common/Icons';
import { workOrdersMng } from '../../../api/WorkOrdersMng';

import WorkOrderState from './WorkOrderState';
import WorkOrderDetails from './WorkOrderDetails';
import SitesAvatar from '../../../../common/SitesAvatar';
import Comments from '../../../../common/comments/Comments';
import WOHistory from './WOHistory';

import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const FONT_COLOR = 'rgb(106 106 106)';

function WorkOrderDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const {
    open,
    onClose,
    workOrder,
    onReverse,
    onStatusClick,
    onStateChange,
    readOnly,
  } = props;
  const printRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openReverseAlert, setOpenReverseAlert] = React.useState(false);
  const [loadingReverse, setLoadingReverse] = React.useState(false);
  if (!workOrder) {
    return null;
  }

  console.log(workOrder);
  const reverse = async () => {
    setOpenReverseAlert(false);
    setLoadingReverse(true);
    try {
      const result = await workOrdersMng.reverseWorkOrder(workOrder._id);

      onClose();
      onReverse();
      toast.success('Work order successfully reserved', {
        autoClose: 900,
      });
    } catch (e) {
      console.log(e);
      toast.error(e.reason);
    } finally {
      setLoadingReverse(false);
    }
  };

  const renderLineItem = ({ icon, label }) => {
    return (
      <div
        style={{
          color: FONT_COLOR,
          marginTop: '4px',
          fontSize: '12px',
        }}>
        <span
          style={{
            position: 'relative',
            top: '2px',
          }}>
          {icon}
        </span>{' '}
        {label}
      </div>
    );
  };

  const renderWorkOrderItems = () => {
    const renderProcessStepContent = (step) => {
      return (
        <div
          style={{
            fontSize: '12px',
            marginTop: '6px',
            color: 'rgb(106, 106, 106)',
          }}>
          <div>
            {!step.isTempMeasure ? <Icons.Equipments size={18} /> : <ITemp />}
            {!step.isTempMeasure && step.equipment && (
              <span
                style={{
                  position: 'relative',
                  marginLeft: '2pzx',
                  top: '-2px',
                }}>
                {step.equipment.name}
              </span>
            )}
          </div>
          <div> {step.description}</div>
        </div>
      );
    };
    const renderProcessStepValue = (step) => {
      if (step.isTempMeasure) {
        return (
          <span
            style={{
              position: 'relative',
            }}>
            <span
              style={{
                color: 'rgb(106, 106, 106)',
              }}>
              {step.value}
            </span>{' '}
            <ICelsius color={'rgb(85, 108, 214)'} />
          </span>
        );
      } else {
        console.log(step);
        const duration = moment.duration(step.timer.state);
        const minutes = duration.minutes();
        const hours = duration.hours();
        const seconds = duration.seconds();
        return (
          <span
            style={{
              textAlign: 'center',
              color: 'rgb(106, 106, 106)',
            }}>
            {hours}h {minutes}m {seconds}s
          </span>
        );
      }
    };

    return workOrder.product.items.map((item, index) => {
      const name = item.isProcessStep ? item.name : item.type.name;
      const image = item.isProcessStep ? item.image : item.type.image;
      return (
        <div
          style={{
            padding: '10px',
            borderTop: index !== 0 ? '1px solid #8080802e' : 'none',
          }}>
          <Grid container>
            <Grid item xs={3}>
              <div
                style={{
                  position: 'relative',
                  textAlign: 'center',
                }}>
                <SitesAvatar name={name} image={image} round={10} size={40} />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{ fontWeight: 500 }}>{name}</div>
              {item.isProcessStep && renderProcessStepContent(item)}
              {!item.isProcessStep && (
                <>
                  {' '}
                  {renderLineItem({
                    icon: <Icons.Code />,
                    label: item.type.code,
                  })}
                  {renderLineItem({
                    icon: <IStore />,
                    label: item.store.name,
                  })}
                  {can(p.globals.viewPrices) &&
                    renderLineItem({
                      icon: <Icons.Price />,
                      label: (
                        <span>
                          {(item.type.price || 0).toLocaleString(undefined, {
                            maximumFractionDigits: 4,
                          })}{' '}
                          AED/{item.type.unit}
                        </span>
                      ),
                    })}
                </>
              )}
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                textAlign: 'center',
              }}>
              {item.isProcessStep && renderProcessStepValue(item)}
              {!item.isProcessStep && (
                <div
                  style={{
                    textAlign: 'center',
                  }}>
                  <Quantity
                    quantity={item.value}
                    unitLabel={item.type.unit}
                    quantitySize={'16px'}
                  />{' '}
                  {can(p.globals.viewPrices) && (
                    <div
                      style={{
                        marginTop: '15px',
                        color: 'gray',
                        fontWeight: '500',
                      }}>
                      <span>
                        {((item.type.price || 0) * item.value).toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 4,
                          }
                        )}{' '}
                        AED
                      </span>
                    </div>
                  )}
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      );
    });
  };

  const renderCostSection = () => {
    if (!can(p.globals.viewPrices)) {
      return <span />;
    }

    const getDirectCost = () => {
      let totalCost = 0;

      workOrder.product.items.forEach((item) => {
        if (!item.isProcessStep) {
          totalCost = totalCost + item.value * (item.type.price || 0);
        }
      });
      return totalCost;
    };

    const directCost = getDirectCost();
    const unitOverheadCost = workOrder.product.overheadCost || 0;
    const totalOverheadCost =
      (workOrder.producedQuantity || 1) * (workOrder.product.overheadCost || 0);

    const renderNum = (num) => {
      return num.toLocaleString(undefined, {
        maximumFractionDigits: 4,
      });
    };

    return (
      <div>
        <div
          className={'label'}
          style={{ marginTop: '20px', marginBottom: '10px' }}>
          Cost
        </div>
        <div
          style={{
            paddingRight: '10px',
            paddingLeft: '10px',
          }}>
          <div
            style={{
              marginTop: '5px',
            }}>
            Direct material cost:{' '}
            <span
              style={{
                float: 'right',
              }}>
              {renderNum(directCost)} AED
            </span>
          </div>
          <div
            style={{
              marginTop: '5px',
            }}>
            Overhead rate:{' '}
            <span
              style={{
                float: 'right',
              }}>
              {' '}
              {renderNum(unitOverheadCost)} AED/
              {workOrder.product.unit}
            </span>{' '}
          </div>
          <div
            style={{
              marginTop: '5px',
            }}>
            Total overhead cost:{' '}
            <span
              style={{
                float: 'right',
              }}>
              {renderNum(unitOverheadCost)} AED
            </span>
          </div>
          <div
            style={{
              marginBottom: '5px',
              padding: '6px',
              marginTop: '20px',
              fontSize: '16px',
              backgroundColor: '#f7f7f7',
              borderRadius: '5px',
            }}>
            Total cost with overhead:{' '}
            <span
              style={{
                float: ' right',
                fontWeight: 'bold',
              }}>
              {renderNum(directCost + totalOverheadCost)} AED
            </span>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <div ref={printRef} className={'work-order'}>
        <DialogTitle>
          <span style={{ marginRight: '5px' }}>
            <span
              style={{
                position: 'relative',
                top: '4px',
                marginRight: '2px',
              }}>
              <IWorkOrder size={22} />
            </span>
            &nbsp;Work Order #{workOrder.seqNum}
          </span>
          <div
            style={{
              position: 'absolute',
              right: '67px',
              top: '8px',
              color: 'black',
            }}>
            <IconButton onClick={handlePrint} className={'hide-print'}>
              {' '}
              <IPrint size={22} color="black" />
            </IconButton>
          </div>
          <CloseBtn
            onClick={() => {
              onClose();
            }}
          />
        </DialogTitle>
        <DialogContent
          style={{
            padding: '10px',
          }}>
          <div
            style={{
              marginTop: '4px',
            }}>
            <WorkOrderDetails
              workOrder={workOrder}
              showIcon={false}
              showCopyBatch
              showReversed
            />
          </div>
          <div
            className={'label'}
            style={{ marginTop: '20px', marginBottom: '10px' }}>
            Materials
          </div>
          <div
            style={{
              padding: '5px',
              border: '1px solid #8080802e',
              borderRadius: '10px',
            }}>
            {renderWorkOrderItems()}
          </div>

          {renderCostSection()}
          <div
            className={'label'}
            style={{ marginTop: '20px', marginBottom: '10px' }}>
            History
          </div>
          <div
            style={{
              marginBottom: '20px',
              color: 'rgb(62 62 62)',
            }}>
            <WOHistory workOrder={workOrder} />
          </div>
          {!readOnly && can(p.factories.updateWorkOrderStatus) && (
            <div className={'hide-print'}>
              <div
                className={'label'}
                style={{
                  marginBottom: '15px',
                }}>
                State
              </div>
              <WorkOrderState workOrder={workOrder} onChange={onStateChange} />
              {workOrder && workOrder.seqNum && (
                <>
                  <div className={'label'}>Comments</div>
                  <Comments subjectId={workOrder.seqNum.toString()} />
                </>
              )}
            </div>
          )}
          {!readOnly && (
            <div className={'hide-print'}>
              <DialogActions>
                <div
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    marginBottom: '30px',
                    marginTop: '20px',
                  }}>
                  <ActionBtn
                    loading={loadingReverse}
                    label={<div>Actions</div>}
                    onClick={() => {
                      setOpenDrawer(true);
                    }}
                  />
                  {/*<ActionBtn*/}
                  {/*  loading={loadingReverse}*/}
                  {/*  disabled={workOrder.reversed}*/}
                  {/*  label={*/}
                  {/*    <div>*/}
                  {/*      <span*/}
                  {/*        style={{*/}
                  {/*          marginRight: '5px',*/}
                  {/*          position: 'relative',*/}
                  {/*          top: '2px',*/}
                  {/*        }}>*/}
                  {/*        <IReverse />*/}
                  {/*      </span>*/}
                  {/*      Reverse*/}
                  {/*    </div>*/}
                  {/*  }*/}
                  {/*  onClick={() => {*/}
                  {/*    setOpenReverseAlert(true);*/}
                  {/*  }}*/}
                  {/*/>*/}
                </div>
              </DialogActions>
            </div>
          )}
        </DialogContent>
      </div>

      <SmartDrawer
        open={openDrawer}
        actions={[
          {
            text: 'Print',
            onClick: () => {
              handlePrint();
              setOpenDrawer(false);
            },
            divider: true,
            icon: <IPrint size={20} />,
          },
          {
            text: 'Reverse',
            secondary: workOrder.reversed
              ? 'The transaction has already been reserved'
              : '',
            disabled: !can(p.factories.reverseWorkOrder) || workOrder.reversed,
            onClick: () => {
              setOpenReverseAlert(true);
            },
            icon: <IReverse />,
          },
        ]}
        onChange={(open) => {
          setOpenDrawer(open);
        }}
      />
      <AlertDialog
        open={openReverseAlert}
        description={'Are you sure you want to reverse work order?'}
        confirmBtn={
          <div style={{ color: 'red' }}>
            <IReverse /> Reverse
          </div>
        }
        onConfirm={() => {
          reverse();
        }}
        onClose={() => {
          setOpenReverseAlert(false);
        }}
      />
    </Dialog>
  );
}

WorkOrderDialog.defaultProps = {
  onReverse: () => {},
  readOnly: false,
};
WorkOrderDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  workOrder: PropTypes.object,
  onReverse: PropTypes.func,
  onStatusClick: PropTypes.func,
  onStateChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default WorkOrderDialog;
