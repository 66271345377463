import React from 'react';
import PropTypes from 'prop-types';

import { App } from '@meronex/app';
import IAddFAB from '@meronex/icons/ai/AiOutlinePlus';
import IDelete from '@meronex/icons/mdc/MdcDeleteEmptyOutline';

import List from '@material-ui/core/List';
import {
  AlertDialog,
  NoData,
  toast,
  InfiniteScroll,
} from '@meronex/components';

import Fade from '@material-ui/core/Fade';
import IAdd from '@meronex/icons/cg/CgAdd';
import Fab from '@material-ui/core/Fab';
import IStores from '@meronex/icons/mdc/MdcWarehouse';
import ICSV from '@meronex/icons/gr/GrDocumentCsv';

import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { useWindowSize } from '@react-hook/window-size';

import { storesMng } from '../../api/storesMng';
import ItemType from './ItemType';
import AddItemTypeDialog from './AddItemTypeDialog';
import EditItemTypeDialog from './EditItemTypeDialog';

import ImportItemTypesCSVDialog from './ImportItemTypesDialog';
import { can, p } from '../../../common/roles';
import ItemTypeActionsDialog from './ItemTypesActionDialog';

const ItemTypesList = React.forwardRef((props, ref) => {
  const {
    store,
    onItemTypeDelete,
    onItemTypeCreate,
    onItemTypeUpdate,
    onAddToStore,
    filterText,
    enableAddToStore,
  } = props;

  const [selectedItemType, setSelectedItemType] = React.useState();

  const [openAddItemType, setOpenAddItemType] = React.useState(false);
  const [openEditItemType, setOpenEditItemType] = React.useState(false);
  const [openDeleteAlert, setOpenDeleteAlert] = React.useState();
  const [openAddAlert, setOpenAddAlert] = React.useState(false);
  const [expandedItemId, setExpandedItemId] = React.useState();
  const [deleting, setDeleting] = React.useState();
  const [deletingError, setDeletingError] = React.useState();

  const [openDrawer, setOpenDrawer] = React.useState();
  const [openActions, setOpenActions] = React.useState();

  const [width, height] = useWindowSize();

  const infiniteScrollRef = React.useRef(null);

  //TODO: refactor to remove it
  const permissions = {
    in: can(p.stores.itemIn),
    out: can(p.stores.itemOut),
    transfer: can(p.stores.transferItem),
    auditItem: can(p.stores.auditStore),
    restore: can(p.stores.itemRestore),
    delete: can(p.stores.itemDelete),
    modify: can(p.stores.modifyItemAmount),
    archive: can(p.stores.itemArchive),
    auditStore: can(p.stores.auditItem),
    CRUDItemTypes: can(p.stores.CRUDItemTypes),
    viewPrices: can(p.globals.viewPrices),
  };

  const [
    openImportItemTypesDialog,
    setOpenImportItemTypesDialog,
  ] = React.useState();

  React.useEffect(() => {
    setDeleting(false);
    setDeletingError(undefined);
  }, [expandedItemId]);

  React.useEffect(() => {
    window.fetch = setTimeout(() => {
      refreshList(false);
    }, 350);
  }, [filterText]);

  const refreshList = () => {
    if (infiniteScrollRef.current) {
      infiniteScrollRef.current.refreshList(false);
    }
  };
  const refreshItems = () => {
    if (infiniteScrollRef.current) {
      infiniteScrollRef.current.refreshItems();
    }
  };
  const fetchItemTypes = async ({ limit, skip }) => {
    console.log(`fetchItemTypes limit: ${limit}, skip: ${skip}`);
    const itemTypes = await storesMng.getAllItemTypesCursor({
      skip,
      limit,
      filters: {
        filterValue: filterText,
      },
    });
    console.log(itemTypes);

    return itemTypes;
  };
  const renderItem = (itemType, index) => {
    return (
      <ItemType
        key={itemType._id}
        permissions={permissions}
        index={index}
        store={store}
        onClick={() => {
          setSelectedItemType(itemType);
          setDeletingError(undefined);
          setOpenActions(true);
        }}
        enableAddToStore={enableAddToStore}
        data={itemType}
      />
    );
  };

  const _onItemTypeUpdate = () => {
    App.eventEmitter.emit('event-item-type-update', ItemTypesList.name);
    onItemTypeUpdate();
    refreshItems();
  };
  const deleteStoreItemType = async () => {
    if (deletingError) {
      setOpenDeleteAlert(false);
    }
    if (selectedItemType) {
      setDeleting(true);
      try {
        const result = await storesMng.deleteStoreItemType(
          selectedItemType._id
        );
        onItemTypeDelete(() => {
          toast.success('StoreItem type deleted', { autoClose: 800 });
          setOpenDeleteAlert(false);
          setDeleting(false);
          refreshItems();
        });
      } catch (e) {
        setDeletingError(e);
        console.dir(e);
      } finally {
        setDeleting(false);
      }
    }
  };
  const addStoreItem = async (itemType) => {
    if (itemType) {
      try {
        const result = await storesMng.addStoreItem(store._id, itemType._id);
        onAddToStore();
        refreshItems();
        setOpenAddAlert(false);
        setOpenAddItemType(false);
        toast.success('Added store item type', { autoClose: 1200 });
      } catch (e) {
        toast.error(e.reason, { autoClose: 2000 });
      }
    }
  };

  const getItemHeight = (item) => {
    let height = 160;
    return height;
  };
  const getAlertMsg = () => {
    if (deletingError) {
      return (
        <div>
          Please make sure the type is not used. {selectedItemType.name} is is
          found in stores:
          <ul>
            {deletingError.details.map((storeItem) => {
              return (
                <li>
                  <IStores /> {storeItem.store.name}
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
    return (
      <div>Are you sure you want to delete type {selectedItemType.name}?</div>
    );
  };

  return (
    <>
      <div style={{ marginTop: '10px' }}>
        <div>
          <InfiniteScroll
            ref={infiniteScrollRef}
            scrollRenderer={(items, startIndex, stopIndex) => {
              console.log(`startIndex: ${startIndex}, stopIndex: ${stopIndex}`);
              const itemType = items[stopIndex];
              if (itemType && itemType.name) {
                return (
                  <div
                    style={{
                      width: '240px',
                      margin: 'auto',
                      backgroundColor: 'rgba(25, 25, 25, 0.74)',
                      color: 'white',
                      borderRadius: '10px',
                      padding: '2px',
                      fontSize: '15px',
                    }}>
                    {stopIndex + 1} - {itemType.name}
                  </div>
                );
              }
              return <span />;
            }}
            height={height - 160}
            remoteRowCount={9999999}
            listStyle={{
              paddingLeft: '0px',
              paddingRight: '0px',
            }}
            renderItem={(data, isScrolling, index) => {
              return renderItem(data, index);
            }}
            itemHeight={(item) => {
              return getItemHeight(item);
            }}
            lastItemHeight={(item) => {
              return getItemHeight(item) + 120;
            }}
            noRowRenderer={() => {
              return (
                <div>
                  <NoData
                    style={{
                      padding: '50px',
                    }}
                    type={'warn'}
                    onClick={() => {
                      setOpenAddItemType(true);
                    }}
                    message={'Add item types'}
                  />
                </div>
              );
            }}
            getListItems={fetchItemTypes}
          />
        </div>
      </div>
      {permissions.CRUDItemTypes && (
        <Fade in={!expandedItemId}>
          <div
            style={{
              position: 'fixed',
              right: '25px',
              bottom: '30px',
            }}>
            <Fab
              color={'primary'}
              onClick={() => {
                setOpenDrawer(true);
                // setOpenAddItemType(true);
              }}>
              <IAddFAB size={30} />
            </Fab>
          </div>
        </Fade>
      )}

      <div>
        <Drawer
          anchor={'bottom'}
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(false);
          }}>
          <List>
            {/*<ListItem*/}
            {/*  button*/}
            {/*  key="improveCSVFile"*/}
            {/*  onClick={() => {*/}
            {/*    setOpenDrawer(false);*/}
            {/*    setOpenImportItemTypesDialog(true);*/}
            {/*  }}>*/}
            {/*  <ListItemIcon>*/}
            {/*    <ICSV size={22} />*/}
            {/*  </ListItemIcon>*/}
            {/*  <ListItemText primary={'Import from CSV'} />*/}
            {/*</ListItem>*/}
            {/*<Divider />*/}
            <ListItem
              button
              key="addItemType"
              onClick={() => {
                setOpenAddItemType(true);
                setOpenDrawer(false);
              }}>
              <ListItemIcon>
                <IAdd size={25} />
              </ListItemIcon>
              <ListItemText primary={'Add Item Type'} />
            </ListItem>
            <Divider />

            <ListItem
              button
              key="close"
              onClick={() => {
                setOpenDrawer(false);
              }}>
              <div
                style={{
                  textAlign: 'center',
                  width: '100%',
                  fontSize: '18px',
                }}>
                Close{' '}
              </div>
            </ListItem>
          </List>
        </Drawer>
      </div>

      {openActions && (
        <ItemTypeActionsDialog
          enableAddToStore={enableAddToStore}
          open={openActions}
          itemType={selectedItemType}
          store={store}
          onAdd={(itemType) => {
            setSelectedItemType(itemType);
            addStoreItem(itemType);
            setOpenActions(false);
          }}
          onEdit={(itemType) => {
            setSelectedItemType(itemType);
            setOpenEditItemType(true);
            setOpenActions(false);
          }}
          onDelete={(itemType) => {
            setSelectedItemType(itemType);
            setOpenDeleteAlert(true);
            setOpenActions(false);
          }}
          onClose={(itemType) => {
            setOpenActions(false);
          }}
        />
      )}
      {openAddItemType && (
        <AddItemTypeDialog
          onAdd={(cb) => {
            if (onItemTypeCreate) {
              onItemTypeCreate(cb);
            }
            refreshList();
          }}
          open={openAddItemType}
          onClose={() => {
            setOpenAddItemType(false);
          }}
        />
      )}

      {openImportItemTypesDialog && (
        <div>
          <ImportItemTypesCSVDialog
            open={openImportItemTypesDialog}
            onClose={() => {
              setOpenImportItemTypesDialog(false);
            }}
          />
        </div>
      )}
      {selectedItemType && (
        <>
          {openEditItemType && (
            <EditItemTypeDialog
              onUpdate={_onItemTypeUpdate}
              open={openEditItemType}
              itemType={selectedItemType}
              onClose={() => {
                setOpenEditItemType(false);
              }}
            />
          )}
          {store && (
            <AlertDialog
              open={openAddAlert}
              onClose={() => {
                setOpenAddAlert(false);
              }}
              onConfirm={() => {
                addStoreItem();
              }}
              confirmBtn={
                <span style={{ color: 'green' }}>
                  Yes{' '}
                  <IAdd
                    size={20}
                    style={{
                      position: 'relative',
                      top: '5px',
                    }}
                  />
                </span>
              }
              description={`Add type "${selectedItemType.name}" to store "${store.name}"?`}
            />
          )}
          <AlertDialog
            open={openDeleteAlert}
            onConfirm={() => {
              deleteStoreItemType();
            }}
            loading={deleting}
            description={getAlertMsg()}
            confirmBtn={
              deletingError ? (
                <div style={{ color: 'red' }}>Ok</div>
              ) : (
                <div style={{ color: 'red' }}>
                  Delete <IDelete size={20} />
                </div>
              )
            }
            onClose={() => {
              setOpenDeleteAlert(false);
            }}
          />
        </>
      )}
    </>
  );
});

ItemTypesList.propTypes = {
  store: PropTypes.object,
  onItemTypeDelete: PropTypes.func,
  onItemTypeCreate: PropTypes.func,
  onItemTypeUpdate: PropTypes.func,
  onAddToStore: PropTypes.func,
  enableAddToStore: PropTypes.bool,
  filterText: PropTypes.string,
};
ItemTypesList.defaultProps = {
  onItemTypeDelete: () => {},
  onItemTypeCreate: () => {},
  onAddToStore: () => {},
  onItemTypeUpdate: () => {},
  enableAddToStore: true,
};

export default ItemTypesList;
