import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@meronex/components';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { Icons } from '../../../common/Icons';
import StoreCategoriesSelect from '../../../settings/storeCategories/components/StoreCategoriesSelect';

function StoreForm(props) {
  const { onChange, data, validateOnInit } = props;

  const [storeCategory, setStoreCategory] = React.useState();

  React.useEffect(() => {
    if (data && onChange) {
      data.category = storeCategory;
      onChange(data);
    }
  }, [storeCategory]);

  return (
    <Form
      validateOnInit={validateOnInit}
      onUpdate={(_formData) => {
        if (_formData) {
          const values = _formData.values;
          if (values) {
            values.productsStore = data.productsStore;
            values.allowNegatives = data.allowNegatives;
            values.isValid = _formData.isValid;
            values.category = storeCategory;
          }
          console.log(values);
          onChange(values);
        }
      }}>
      <TextField
        name={'name'}
        label="Name"
        autoFocus={true}
        defaultValue={data.name}
        variant="outlined"
        validate={(v) => {
          if (!v || v === '') return 'Name is required';
          return true;
        }}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <StoreCategoriesSelect
        value={data.category}
        onChange={(c) => {
          console.log(c);
          setStoreCategory(c);
        }}
      />
      <div
        style={{
          marginTop: '12px',
          marginBottom: '5px',
          color: 'gray',
        }}>
        <label>
          <Icons.Configure />
          <span
            style={{
              marginLeft: '5px',
            }}>
            Configure
          </span>
        </label>
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={data.allowNegatives}
              onChange={() => {
                const _data = Object.assign({}, data);
                _data.allowNegatives = !_data.allowNegatives;
                onChange(_data);
              }}
              name="allowNegatives"
              color="primary"
            />
          }
          label="Allow negative quantities"
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={data.productsStore}
              onChange={() => {
                const _data = Object.assign({}, data);
                _data.productsStore = !_data.productsStore;
                onChange(_data);
              }}
              name="checkedB"
              color="primary"
            />
          }
          label="Products Store"
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={data.enableSections}
              onChange={() => {
                const _data = Object.assign({}, data);
                _data.enableSections = !_data.enableSections;
                onChange(_data);
              }}
              name="enableSections"
              color="primary"
            />
          }
          label="Enable Sections"
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={data.enableDynamicPrice}
              onChange={() => {
                const _data = Object.assign({}, data);
                _data.enableDynamicPrice = !_data.enableDynamicPrice;
                onChange(_data);
              }}
              name="enableDynamicPrice"
              color="primary"
            />
          }
          label="Enable dynamic item pricing"
        />
      </div>
    </Form>
  );
}
StoreForm.propTypes = {
  onChange: PropTypes.func,
  validateOnInit: PropTypes.bool,
};

StoreForm.defaultProps = {
  data: {},
  onChange: (data) => console.log(data),
};
export default StoreForm;
