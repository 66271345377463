import { App } from '@meronex/app';

export const can = (action, user = App.getUser(), negation = false) => {
  const actionPath = action.split('.');
  const category = actionPath[0];
  const actionId = actionPath[1];

  if (user) {
    const rootOrAdmin = (user.roles || []).some((i) =>
      ['admin', 'root'].includes(i)
    );
    if (rootOrAdmin) {
      return !negation; // negation statement is like hide x, if the statement is negation,
      // then false means the view is possible or item is accessible
    }
  }

  if (
    user &&
    user.settings &&
    user.settings.sites &&
    user.settings.sites.role &&
    user.settings.sites.role[category] &&
    user.settings.sites.role[category][actionId]
  ) {
    return true;
  }

  return false;
};
export const p = {
  stores: {
    itemIn: 'stores.itemIn',
    itemOut: 'stores.itemOut',
    itemArchive: 'stores.itemArchive',
    itemRestore: 'stores.itemRestore',
    itemDelete: 'stores.itemDelete',
    transferItem: 'stores.transferItem',
    auditItem: 'stores.auditItem',
    modifyItemAmount: 'stores.modifyItemAmount',
    reverseTransaction: 'stores.reverseTransaction',
    auditStore: 'stores.auditStore',
    CRUDItemTypes: 'stores.CRUDItemTypes',
  },
  factories: {
    CRUDFactories: 'factories.CRUDFactories',
    CRUDWorkOrders: 'factories.CRUDWorkOrders',
    modifyOthersWorkOrder: 'factories.modifyOthersWorkOrder',
    deleteOthersWorkOrder: 'factories.deleteOthersWorkOrder',
    CRUDProducts: 'factories.CRUDProducts',
    updateWorkOrderStatus: 'factories.updateWorkOrderStatus',
    reverseWorkOrder: 'factories.reverseWorkOrder',
  },
  globals: {
    viewPrices: 'globals.viewPrices',
    viewSettings: 'globals.viewSettings',
    hideFactoriesModule: 'globals.hideFactoriesModule',
  },
};
