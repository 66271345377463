import React from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';

import { App } from '@meronex/app';
import IArrow from '@meronex/icons/bi/BisDownArrow';
import { CloseBtn } from '@meronex/components';

import { direction } from 'direction';

import HeaderMenu from './HeaderMenu';
import Filter from '../../common/Filter';

const TopBar = React.forwardRef((props, ref) => {
  const {
    icon,
    title,
    titleMaxWidth,
    onClose,
    onTitleDropdownClick,
    showMenu,
    menuStyle,
    activeView,
    subtitle,
    itemsCount,
    itemsUnit,
    filterProps,
    onRefresh,
    children,
  } = props;

  const titleDirection = direction(title);

  const filterRef = React.useRef();

  React.useImperativeHandle(ref, () => ({
    reset: () => {
      if (filterRef && filterRef.current) {
        filterRef.current.reset();
      }
    },
  }));

  const renderItemUnit = () => {
    if (typeof itemsUnit === 'string') {
      return (
        <span>
          &nbsp;{itemsUnit}
          {itemsCount > 1 ? 's' : ''}
        </span>
      );
    } else if (typeof itemsUnit === 'object') {
      if (itemsCount > 1 && itemsUnit.plural) {
        return <span>&nbsp;{itemsUnit.plural}</span>;
      } else if (itemsCount <= 1 && itemsUnit.single) {
        return <span>&nbsp;{itemsUnit.single}</span>;
      }
    }
  };
  return (
    <>
      <div
        className={'noselect'}
        style={{
          display: 'flex',
          position: 'relative',
          paddingBottom: '0px',
          marginTop: '5px',
          marginBottom: '5px',
        }}
        onClick={() => {
          if (onTitleDropdownClick) {
            onTitleDropdownClick();
          }
        }}>
        <div
          style={{
            position: 'relative',
            cursor: onTitleDropdownClick ? 'pointer' : '',
          }}>
          {icon && (
            <div
              style={{
                display: 'inline-block',
                marginRight: '8px',
                position: 'relative',
              }}>
              {icon}
            </div>
          )}
          {onTitleDropdownClick && (
            <div
              style={{
                position: 'relative',
                display: 'inline-block',
                marginLeft: '0px',
                top: '0px',
                marginRight: '5px',
              }}>
              <IArrow size={14} />
            </div>
          )}
          {title && (
            <div
              style={{
                display: 'inline-block',
                fontWeight: 400,
                position: 'relative',
                top: '-2px',
                fontSize: '20px',
              }}>
              {titleDirection === 'rtl' && Boolean(itemsCount) && (
                <span
                  style={{
                    marginRight: '5px',
                    display: 'inline-block',
                    float: 'none',
                  }}>
                  ({itemsCount})
                </span>
              )}

              <Truncate
                width={App.utils.isDesktop() ? 1000 : titleMaxWidth}
                lines={1}
                ellipsis={
                  <span className={titleDirection === 'rtl' ? 'rtl' : ''}>
                    ..
                  </span>
                }>
                {title}
              </Truncate>
              {titleDirection === 'ltr' && Boolean(itemsCount) && (
                <span
                  style={{
                    marginLeft: '5px',
                    display: 'inline-block',
                    float: 'none',
                    fontSize: '12px',
                    color: 'gray',
                  }}>
                  ({itemsCount}
                  {renderItemUnit()})
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      {subtitle && (
        <div
          style={{
            fontWeight: 400,
            fontSize: '15px',
            display: 'block',
            color: 'gray',
            marginTop: '2px',
          }}>
          {subtitle}
        </div>
      )}
      <div>{filterProps && <Filter ref={filterRef} {...filterProps} />} </div>
      {children}
      {showMenu && (
        <HeaderMenu
          activeItemId={activeView}
          style={menuStyle}
          onRefresh={onRefresh}
        />
      )}
      {onClose && (
        <CloseBtn
          style={{
            top: '4px',
          }}
          onClick={() => {
            onClose();
          }}
        />
      )}
    </>
  );
});
TopBar.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  titleMaxWidth: PropTypes.number,
  showMenu: PropTypes.bool,
  filterProps: PropTypes.shape(Filter.propTypes),
  menuStyle: PropTypes.object,
  activeView: PropTypes.string,
  subtitle: PropTypes.string,
  itemsCount: PropTypes.number,
  itemsUnit: PropTypes.string,
  onTitleDropdownClick: PropTypes.func,
  onRefresh: PropTypes.func,
  onClose: PropTypes.func,
};

TopBar.defaultProps = {
  titleMaxWidth: 280,
};
export default TopBar;
