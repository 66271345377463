import React from 'react';
import { App } from '@meronex/app';

import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { ConstructionDialog } from '@meronex/components';

import IStores from '@meronex/icons/mdc/MdcWarehouse';

import MenuTile from './MenuTile';
import { sitesMng } from '../../sites/api';
import { Icons } from '../../common/Icons';
import { can, p } from '../../common/roles';

export default function MngMenu(props) {
  const [sites, setSites] = React.useState([]);

  const [openConstructionDialog, setOpenConstructionDialog] = React.useState(
    false
  );
  const fetchSites = async () => {
    console.log('fetch sites..');
    try {
      const sites = await sitesMng.getSites();
      console.log(sites);
      setSites(sites);
      App.eventsManager.emit('event-sites-loaded', MngMenu.name, sites);
    } catch (e) {
      console.log(e);
    }
  };

  const refreshFeed = () => {
    App.eventsManager.emit('event-refresh-feed', MngMenu.name);
  };
  React.useEffect(() => {
    // code to run on component mount
    fetchSites();
    // ensure that we call fetch sites when reconnect, this happen
    // on refresh the browser or reconnection while the page is on.
    App.eventsManager.on('event-connected', MngMenu, () => {
      fetchSites();
    });
  }, []);

  const openFormFunc = (openForm) => {
    if (!sites || sites.length < 1) {
      toast.error('No sites available, please ask admin to add one.');
    } else {
      openForm(true);
    }
  };
  const setActiveView = (view) => {
    App.eventsManager.emit('event-active-view', MngMenu.name, {
      activeView: view,
    });
  };
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          textAlign: 'center',
          flexWrap: 'wrap',
          overflow: 'auto',
        }}>
        <MenuTile
          title={'Stores'}
          icon={<IStores />}
          onClick={() => {
            setActiveView('stores');
          }}
          bgColor={'#e10000'}
        />
        {!can(p.globals.hideFactoriesModule, null, true) && (
          <MenuTile
            title={'Production'}
            icon={<Icons.Factories />}
            onClick={() => {
              setActiveView('factories');
            }}
            bgColor={'rgb(16 115 110 / 67%)'}
          />
        )}
        <MenuTile
          title={'Equipments'}
          icon={<Icons.Equipments />}
          onClick={() => {
            setActiveView('equipments');
          }}
          bgColor={'rgba(114, 5, 121, 0.67)'}
        />
        <MenuTile
          title={'Feed'}
          icon={<Icons.Feed />}
          onClick={() => {
            setActiveView('feed');
          }}
        />
        {/*<MenuTile*/}
        {/*  title={'Issues'}*/}
        {/*  icon={<Icons.Issues />}*/}
        {/*  bgColor={'rgb(233 195 13)'}*/}
        {/*  onClick={() => {*/}
        {/*    setActiveView('issues');*/}
        {/*  }}*/}
        {/*/>*/}
        <MenuTile
          title={'Tasks'}
          icon={<Icons.Tasks size={38} />}
          onClick={() => {
            // setActiveView('apps');
            setOpenConstructionDialog(true);
          }}
          bgColor={'#ff9800'}
        />
        <MenuTile
          title={'Settings'}
          icon={<Icons.Settings />}
          onClick={() => {
            setActiveView('settings');
          }}
          bgColor={'#009688'}
        />
      </div>
      <ConstructionDialog
        open={openConstructionDialog}
        onClose={() => {
          setOpenConstructionDialog(false);
        }}
      />
    </div>
  );
}
