import React from 'react';
import PropTypes from 'prop-types';

import { ActionBtn, CloseBtn } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

//TODO: replace with <Icons.X/>
import IList from '@meronex/icons/ios/MdList';

import EditMemberDialog from './EditMemberDialog';
import MembersList from './MembersList';

import { membersMng } from '../api/membersMng';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function MembersDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose } = props;

  const [openEditMember, setOpenEditMember] = React.useState();
  const [selectedMember, setSelectedMember] = React.useState();
  const [members, setMembers] = React.useState();

  const getMembers = async () => {
    console.log('getMembers');
    const members = await membersMng.getMembers();
    setMembers(members);
  };

  React.useEffect(() => {
    getMembers();
  }, []);
  React.useEffect(() => {
    if (selectedMember) {
      setOpenEditMember(true);
    }
  }, [selectedMember]);

  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        <IList /> Members
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{
          padding: '0px',
        }}>
        <div
          style={{
            marginBottom: '100px',
          }}>
          <MembersList
            members={members}
            onClick={(member) => {
              console.log(member);
            }}
            onEdit={(member) => {
              console.log(member);
              setSelectedMember(member);
              setOpenEditMember(true);
            }}
            onDelete={getMembers}
          />
        </div>
        {openEditMember && (
          <EditMemberDialog
            open={openEditMember}
            member={selectedMember}
            onEdit={getMembers}
            onClose={() => {
              setOpenEditMember(false);
              setSelectedMember(false);
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

MembersDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default MembersDialog;
