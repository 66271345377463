import React from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

import { App } from '@meronex/app';
import IFilterOutline from '@meronex/icons/mdc/MdcFilterMenuOutline';
import AiOutlineSearch from '@meronex/icons/ai/AiOutlineSearch';

import { storeCategoriesMng } from '../../../settings/storeCategories/api/storeCategoriesMng';

const Filter = React.forwardRef((props, ref) => {
  const { onFilter, onCategoryId, borderRadius, storesCount, visible } = props;

  const initData = {
    dateType: '',
    range: [null, null],
    filterValue: '',
  };
  const [expanded, setExpanded] = React.useState(false);
  const [data, setData] = React.useState(initData);
  const [applied, setApplied] = React.useState();

  const [categoryId, setCategoryId] = React.useState('any');
  const [storeCategories, setStoreCategories] = React.useState();

  const fetchCategoriesOption = async () => {
    const result = await storeCategoriesMng.getStoreCategories();
    console.log(result);
    setStoreCategories(result);
    loadSavedCategory(result);
  };

  const loadSavedCategory = (_storeCategories = storeCategories) => {
    console.log('load saved category');
    const savedCategoryId = App.clientSettings.getItem('storeCategoryId');
    if (savedCategoryId) {
      setCategoryId(savedCategoryId);
    }
  };

  React.useEffect(() => {
    if (visible) {
      loadSavedCategory();
    }
  }, [visible]);
  React.useEffect(() => {
    fetchCategoriesOption();
  }, []);

  React.useEffect(() => {
    App.clientSettings.setItem('storeCategoryId', categoryId);
    onCategoryId(categoryId);
    if (categoryId === 'any' && !data.filterValue) {
      setApplied(false);
    } else {
      setApplied(true);
    }
    setExpanded(false);
  }, [categoryId]);

  const _onFilter = (value) => {
    if (onFilter) {
      onFilter(value);
    }
    if (!value) {
      setApplied(false);
      setExpanded(false);
    } else {
      setApplied(true);
      setExpanded(true);
    }
  };

  const inputProps = {
    style: {
      borderRadius,
      height: '50px',
    },
    startAdornment: (
      <InputAdornment position="start">
        <IconButton edge="start">
          <AiOutlineSearch />
        </IconButton>
      </InputAdornment>
    ),
  };

  let category;
  if (categoryId && storeCategories) {
    category = storeCategories.find((c) => c._id === categoryId);
  }

  console.log(categoryId);
  return (
    <div
      style={{
        marginTop: '-12px',
      }}>
      <Grid
        container
        spacing={1}
        style={{
          marginRight: '10px',
        }}>
        <Grid item xs={10}>
          <TextField
            fullWidth
            style={{
              height: '50px',
            }}
            variant={'outlined'}
            placeholder={`search ${
              storesCount ? `${storesCount} ` : ''
            }stores ${category ? `in ${category.name}` : ''}`}
            value={data.filterValue}
            margin="normal"
            InputProps={inputProps}
            onChange={(e) => {
              const _data = { ...data };
              _data.filterValue = e.target.value;
              setData(_data);
              _onFilter(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <IconButton
            onClick={() => {
              setExpanded(!expanded);
            }}
            style={{
              position: 'relative',
              top: '16px',
              height: '50px',
              color: '#6a6a6a',
              borderRadius: '5px',
              backgroundColor: applied ? '#43c16a29' : 'inherit',
              border: '1px solid #c4c4c4',
              width: '100%',
            }}>
            <IFilterOutline size={18} />
          </IconButton>
        </Grid>
      </Grid>
      <AnimateHeight duration={200} height={expanded ? 80 : 0}>
        <TextField
          fullWidth
          style={{
            height: '50px',
          }}
          label={'Category'}
          variant={'outlined'}
          value={categoryId}
          margin="normal"
          select
          onChange={(e) => {
            // _onCategory(e.target.value);
            setCategoryId(e.target.value);
            // _onFilter(e.target.value);
          }}>
          <MenuItem value={'any'}>Any</MenuItem>
          {Array.isArray(storeCategories) &&
            storeCategories.map((c) => {
              return <MenuItem value={c._id}>{c.name}</MenuItem>;
            })}
        </TextField>
      </AnimateHeight>
    </div>
  );
});
Filter.propTypes = {
  onFilter: PropTypes.func,
  filterOnApply: PropTypes.bool,
  filterValue: PropTypes.string,
  onApply: PropTypes.func,
  onReset: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  expandable: PropTypes.bool,
  autoExpand: PropTypes.bool,
  enableDateRange: PropTypes.bool,
  enableQrCode: PropTypes.bool,
  borderRadius: PropTypes.string,
  extraFields: PropTypes.node,
};
Filter.defaultProps = {
  expandable: false,
  enableDateRange: false,
  filterOnApply: false,
  height: 200,
  borderRadius: '5px',
  enableQrCode: false,
  autoExpand: false,
  onApply: (v) => {},
  onReset: (v) => {},
  onFilter: (v) => {},
};
export default Filter;
