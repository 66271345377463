import React from 'react';
import PropTypes from 'prop-types';

import { Form } from '@meronex/components';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import { rolesMng } from '../../roles/api/rolesMng';

function MemberForm(props) {
  const { onChange = () => {}, data = {}, validateOnInit } = props;

  const [roles, setRoles] = React.useState();

  console.log(data);
  const getRoles = async () => {
    const roles = await rolesMng.getRoles();
    setRoles(roles);
  };

  React.useEffect(() => {
    getRoles();
  }, []);

  const renderRoles = () => {
    if (!Array.isArray(roles)) {
      return <MenuItem> Loading...</MenuItem>;
    } else if (roles.length === 0) {
      return <MenuItem>No roles defined</MenuItem>;
    }
    return roles.map((r) => {
      return <MenuItem value={r._id}>{r.name}</MenuItem>;
    });
  };

  const getRoleId = (member) => {
    if (
      member &&
      member.settings &&
      member.settings.sites &&
      member.settings.sites.role
    ) {
      return member.settings.sites.role._id;
    }
  };
  return (
    <div>
      <Form
        validateOnInit={validateOnInit}
        blurDelay={500}
        onUpdate={(_data) => {
          console.log(_data);
          if (_data) {
            const values = _data.values;

            if (values && values.roleId) {
              values.isValid = true;
            }
            onChange(values);
          }
        }}>
        <TextField
          placeholder={'select role'}
          variant="outlined"
          label="Role"
          name={'roleId'}
          defaultValue={getRoleId(data)}
          required
          fullWidth
          select>
          {renderRoles()}
        </TextField>
      </Form>
    </div>
  );
}
MemberForm.propTypes = {
  data: PropTypes.object,
  validateOnInit: PropTypes.bool,
  onChange: PropTypes.func,
};

MemberForm.defaultProps = {
  data: {},
  validateOnInit: false,
  onChange: (data) => console.log(data),
};
export default MemberForm;
