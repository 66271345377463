import React from 'react';
import PropTypes from 'prop-types';

import { Form } from '@meronex/components';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';

const renderSectionTitle = (name) => {
  return (
    <div
      style={{
        marginTop: '12px',
        marginBottom: '5px',
        fontWeight: 'bold',
        fontSize: '16px',
      }}>
      <label>
        <span
          style={{
            marginLeft: '5px',
          }}>
          {name}
        </span>
      </label>
    </div>
  );
};

const RoleItem = (props) => {
  const { label, _id, onChange, checked, category, role } = props;

  const _onChange = (v) => {
    const checked = v.currentTarget.checked;
    onChange({
      _id,
      category,
      checked,
    });
  };

  const isChecked = () => {
    if (role) {
      if (role[category]) {
        if (role[category][_id]) {
          return true;
        }
      }
    }
    return false;
  };
  return (
    <ListItem
      style={{
        paddingBottom: '0px',
        paddingTop: '0px',
        borderBottom: '1px solid #80808026',
      }}>
      <Grid container>
        <Grid
          item
          xs={10}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}>
          {label}
        </Grid>
        <Grid item xs={2}>
          <Switch checked={isChecked()} onChange={_onChange} name={_id} />
        </Grid>
      </Grid>
    </ListItem>
  );
};

function RoleForm(props) {
  const { onChange = () => {} } = props;

  const [role, setRole] = React.useState(props.role || {});

  React.useEffect(() => {
    onChange(role);
  }, [role]);

  const updateName = (v) => {
    role.name = v.target.value;
    setRole({ ...role });
  };
  const updateRole = ({ category, _id, checked }) => {
    if (!role[category]) {
      role[category] = {};
    }
    role[category][_id] = checked;
    setRole({ ...role });
  };
  const renderFactoriesPermissions = () => {
    return (
      <div>
        {renderSectionTitle('Production Sites')}
        <List>
          <RoleItem
            role={role}
            category={'factories'}
            _id={'CRUDFactories'}
            label={'Create, modify or delete production sites'}
            onChange={updateRole}
          />
          <RoleItem
            role={role}
            category={'factories'}
            _id={'CRUDWorkOrders'}
            label={'Create, modify or delete work orders'}
            onChange={updateRole}
          />
          <RoleItem
            role={role}
            category={'factories'}
            _id={'modifyOthersWorkOrder'}
            label={'Modify others work order'}
            onChange={updateRole}
          />
          <RoleItem
            role={role}
            category={'factories'}
            _id={'deleteOthersWorkOrder'}
            label={'Delete others work order'}
            onChange={updateRole}
          />
          <RoleItem
            role={role}
            category={'factories'}
            _id={'updateWorkOrderStatus'}
            label={'Update work order status'}
            onChange={updateRole}
          />
          <RoleItem
            role={role}
            category={'factories'}
            _id={'reverseWorkOrder'}
            label={'Reverse work order'}
            onChange={updateRole}
          />

          <RoleItem
            role={role}
            category={'factories'}
            _id={'CRUDProducts'}
            label={'Create, modify or delete products'}
            onChange={updateRole}
          />
        </List>
      </div>
    );
  };
  const renderStoresPermissions = () => {
    return (
      <div>
        {renderSectionTitle('Stores')}
        <List>
          <RoleItem
            role={role}
            category={'stores'}
            _id={'itemIn'}
            label={'Item in'}
            onChange={updateRole}
          />
          <RoleItem
            role={role}
            category={'stores'}
            _id={'itemOut'}
            label={'Item out'}
            onChange={updateRole}
          />
          <RoleItem
            role={role}
            category={'stores'}
            _id={'transferItem'}
            label={'Transfer'}
            onChange={updateRole}
          />
          <RoleItem
            role={role}
            category={'stores'}
            _id={'auditItem'}
            label={'Audit item'}
            onChange={updateRole}
          />
          <RoleItem
            role={role}
            category={'stores'}
            _id={'modifyItemAmount'}
            label={'Modify item amount'}
            onChange={updateRole}
          />
          <RoleItem
            role={role}
            category={'stores'}
            _id={'itemArchive'}
            label={'Archive item'}
            onChange={updateRole}
          />
          <RoleItem
            role={role}
            category={'stores'}
            _id={'itemRestore'}
            label={'Restore item'}
            onChange={updateRole}
          />
          <RoleItem
            role={role}
            category={'stores'}
            _id={'itemDelete'}
            label={'Delete item'}
            onChange={updateRole}
          />

          <RoleItem
            role={role}
            category={'stores'}
            _id={'auditStore'}
            label={'Audit store'}
            onChange={updateRole}
          />

          <RoleItem
            role={role}
            category={'stores'}
            _id={'reverseTransaction'}
            label={'Reverse transaction'}
            onChange={updateRole}
          />
          <RoleItem
            role={role}
            category={'stores'}
            _id={'CRUDItemTypes'}
            label={'Create, modify or delete item types'}
            onChange={updateRole}
          />
        </List>
      </div>
    );
  };

  const renderGlobalPermissions = () => {
    return (
      <div>
        {renderSectionTitle('Global')}
        <List>
          <RoleItem
            role={role}
            category={'globals'}
            _id={'viewPrices'}
            label={'View prices'}
            onChange={updateRole}
          />
          <RoleItem
            role={role}
            category={'globals'}
            _id={'hideFactoriesModule'}
            label={'Hide productions app'}
            onChange={updateRole}
          />
          <RoleItem
            role={role}
            category={'globals'}
            _id={'viewSettings'}
            label={'Access application settings'}
            onChange={updateRole}
          />
        </List>
      </div>
    );
  };
  return (
    <div>
      <TextField
        name={'name'}
        label="Role Name"
        autoFocus={true}
        defaultValue={(role || {}).name}
        variant="outlined"
        onChange={updateName}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      {renderStoresPermissions()}
      {renderFactoriesPermissions()}
      {renderGlobalPermissions()}
    </div>
  );
}
RoleForm.propTypes = {
  data: PropTypes.object,
  validateOnInit: PropTypes.bool,
  onChange: PropTypes.func,
};

RoleForm.defaultProps = {
  data: {},
  validateOnInit: false,
  onChange: (data) => console.log(data),
};
export default RoleForm;
