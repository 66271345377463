import React from 'react';
import PropTypes from 'prop-types';

import Truncate from 'react-truncate';
import { direction } from 'direction';

import Grid from '@material-ui/core/Grid/Grid';

import { App } from '@meronex/app';

import ITime from '@meronex/icons/ios/MdTime';
import IWarehouse from '@meronex/icons/mdc/MdcWarehouse';
import IExpire from '@meronex/icons/bi/BiCalendarX';
import IReverse from '@meronex/icons/fa/FaUndo';
import IPerson from '@meronex/icons/md/MdPerson';

import IPackaging from '@meronex/icons/mdc/MdcPackageVariant';
import IWarn from '@meronex/icons/ti/TiWarningOutline';
import IData from '@meronex/icons/bs/BsClipboardData';
import IVerify from '@meronex/icons/mdc/MdcMicroscope';

import { Icons } from '../../../../common/Icons';
import { helpers } from '../../../../common/helpers';

import Quantity from '../../../../common/Quantity';
import SitesAvatar from '../../../../common/SitesAvatar';
import SitesUserName from '../../../../common/SitesUserName';
import { toast } from '@meronex/components';
import { can, p } from '../../../../common/roles';
import { workOrderHelpers } from '../helpers';

const { utils } = App;

const FONT_COLOR = 'rgb(106 106 106)';
const ICON_SIZE = 16;

export const WorkOrderDetails = React.forwardRef((props, ref) => {
  const {
    workOrder,
    showIcon,
    showReversed,
    showExpiry,
    showCreationDate,
    showId,
    showCopyBatch,
  } = props;

  if (!workOrder) {
    return null;
  }

  const nameDir = direction(workOrder.product.name);

  const renderState = () => {
    if (!workOrder.state) {
      return null;
    }

    return (
      <div style={{ fontSize: '12px', marginTop: '2px' }}>
        <div>
          {workOrder.state.qualityVerified && (
            <span>
              <IVerify /> Quality verified {'   '}
            </span>
          )}
          {workOrder.state.dataEntry && (
            <span>
              <IData /> Data checked
            </span>
          )}
        </div>
        {workOrder.state.flagged && workOrder.state.flagged.reason && (
          <div
            style={{
              color: 'red',
            }}>
            <IWarn /> {workOrder.state.flagged.reason}
          </div>
        )}
      </div>
    );
  };

  const renderLineItem = ({ icon, label }) => {
    return (
      <div style={{ fontSize: '12px', marginTop: '2px' }}>
        <span style={{ position: 'relative', left: '2px' }}>{icon}</span>
        <span
          style={{
            marginLeft: '10px',
          }}>
          {label}
        </span>
      </div>
    );
  };

  const getTotalCost = () => {
    let totalCost = 0;

    workOrder.product.items.forEach((item) => {
      if (!item.isProcessStep) {
        totalCost = totalCost + item.value * (item.type.price || 0);
      }
    });
    return totalCost;
  };

  const getUnitCost = () => {
    let unitCost = 0;
    const totalCost = getTotalCost();
    unitCost = totalCost / (workOrder.producedQuantity || 1);
    return unitCost;
  };

  const renderCost = () => {
    if (!can(p.globals.viewPrices)) {
      return <div />;
    }
    return (
      <div
        style={{
          marginTop: '20px',
          color: 'gray',
          fontWeight: '500',
        }}>
        {getTotalCost().toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}{' '}
        AED
      </div>
    );
  };

  const copyBatch = () => {
    try {
      navigator.clipboard.writeText(workOrder.batchNum);
      console.log(`${workOrder.batchNum} copied!`);
      toast.success('Batch number successfully to clipboard!');
    } catch (e) {
      console.log(e);
      toast.error('Failed to batch number to clipboard.');
    }
  };

  const renderWarn = () => {
    let warn = false;
    if (workOrder.state && workOrder.state.flagged) {
      warn = true;
    } else if (workOrder.status === 'flagged') {
      warn = true;
    }

    if (warn) {
      return (
        <IWarn
          size={22}
          color={'red'}
          style={{
            marginRight: '5px',
          }}
        />
      );
    }
    return null;
  };
  return (
    <div ref={ref}>
      <div
        className={'title'}
        style={{
          fontWeight: '400',
          fontSize: '16px',
          paddingTop: '4px',
          marginBottom: '5px',
        }}>
        <div>
          <span
            style={{
              position: 'relative',
              top: '2px',
            }}>
            {renderWarn()}
            <Icons.Products size={14} />
          </span>
          <Truncate
            width={280}
            lines={1}
            ellipsis={
              <span className={nameDir === 'rtl' ? 'rtl' : ''}>..</span>
            }>
            {workOrder.reversed ? <span>&nbsp;(R)</span> : ''}{' '}
            {workOrder.product.name}
          </Truncate>
        </div>
        <div>
          <span
            className={'created-at'}
            style={{
              float: 'right',
              fontSize: '12px',
              color: 'gray',
              position: 'relative',
              top: '2px',
              right: '12px',
            }}>
            {' '}
            {utils.time.timeAgo(workOrder.createdAt)}
            <span> | #{workOrder.seqNum}</span>
            <br />
          </span>
        </div>
      </div>

      <Grid container>
        <Grid item xs={3}>
          <div
            style={{
              position: 'relative',
              top: '4px',
              left: '0px',
              textAlign: 'center',
            }}>
            <SitesAvatar
              image={workOrder.product.image}
              name={workOrder.product.name}
              size={65}
              round={10}
            />
          </div>
        </Grid>
        <Grid item xs={7}>
          <div
            style={{
              color: FONT_COLOR,
            }}>
            <div
              style={{
                fontSize: '12px',
                marginTop: '4px',
              }}>
              <span
                style={{
                  position: 'relative',
                  top: '4px',
                }}>
                <IWarehouse size={ICON_SIZE} color={FONT_COLOR} />
              </span>
              {workOrder.destinationStore && (
                <span
                  style={{
                    marginLeft: '6px',
                  }}>
                  {workOrder.destinationStore.name}
                </span>
              )}
            </div>
            {showCreationDate && (
              <>
                <div style={{ fontSize: '12px', marginTop: '2px' }}>
                  <span style={{ position: 'relative', top: '4px' }}>
                    <ITime size={ICON_SIZE} />
                  </span>{' '}
                  {utils.time.fullFormat(workOrder.createdAt, true)}
                </div>
                {workOrder.batchNum ? (
                  <span onClick={copyBatch}>
                    <span
                      style={{
                        position: 'relative',
                        top: '2px',
                        marginRight: '2px',
                      }}>
                      <Icons.Batch />
                    </span>
                    {workOrder.batchNum}
                    {showCopyBatch && <span className={'link'}> (copy)</span>}
                  </span>
                ) : (
                  ''
                )}
              </>
            )}

            {workOrder.draft && workOrder.draft.createdBy && (
              <div style={{ fontSize: '12px', marginTop: '2px' }}>
                <span style={{ position: 'relative', top: '4px' }}>
                  <IPerson size={ICON_SIZE} />
                </span>{' '}
                <SitesUserName profile={workOrder.draft.createdBy} />
              </div>
            )}
            {showReversed && workOrder.reversed && (
              <div style={{ fontSize: '12px', marginTop: '6px' }}>
                <span
                  style={{ position: 'relative', top: '2px', right: '-4px' }}>
                  <IReverse size={10} />
                </span>{' '}
                <span
                  style={{
                    position: 'relative',
                    left: '6px',
                  }}>
                  {utils.time.fullFormat(workOrder.reversed.date)}
                </span>
              </div>
            )}

            {workOrder.product.packaging && (
              <div style={{ fontSize: '12px', marginTop: '6px' }}>
                <span
                  style={{ position: 'relative', top: '2px', right: '-4px' }}>
                  <IPackaging size={12} />
                </span>{' '}
                <span
                  style={{
                    position: 'relative',
                    left: '6px',
                  }}>
                  {workOrderHelpers.getPackagedQuantity(workOrder)}
                </span>
              </div>
            )}

            {showId &&
              workOrder.uniqid &&
              renderLineItem({
                icon: <Icons.Code size={10} />,
                label: <span>{workOrder.uniqid}</span>,
              })}
            {renderLineItem({
              icon: <Icons.Price size={15} />,
              label: (
                <span
                  style={{
                    position: 'relative',
                    top: '-2px',
                    left: '-2px',
                  }}>
                  {getUnitCost().toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{' '}
                  AED/
                  {workOrder.product.unit}
                </span>
              ),
            })}
            {showExpiry &&
              workOrder.expiration &&
              renderLineItem({
                icon: <IExpire size={15} />,
                label: (
                  <span
                    style={{
                      position: 'relative',
                      left: '-4px',
                    }}>
                    {helpers.computeTimeStr({
                      date: workOrder.expiration.expirationDate,
                      unit: workOrder.expiration.unit,
                    })}
                  </span>
                ),
              })}
            {renderState()}
          </div>
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          <div style={{ position: 'relative', top: '25px', left: '-2px' }}>
            <Quantity
              quantity={workOrder.producedQuantity}
              unitLabel={workOrder.product.unit}
              enableChangeDirAnimation={false}
              quantitySize={'22px'}
              unitLabelStyle={{
                fontSize: '12px',
              }}
            />
            {renderCost()}
          </div>
        </Grid>
      </Grid>
    </div>
  );
});
WorkOrderDetails.propTypes = {
  workOrder: PropTypes.object,
  showIcon: PropTypes.bool,
  showReversed: PropTypes.bool,
  showExpiry: PropTypes.bool,
  showCreationDate: PropTypes.bool,
  showId: PropTypes.bool,
  showCopyBatch: PropTypes.bool,
};

WorkOrderDetails.defaultProps = {
  showIcon: true,
  showReversed: false,
  showExpiry: true,
  showId: true,
  showCreationDate: true,
  showCopyBatch: false,
};
export default WorkOrderDetails;
