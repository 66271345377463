import React from 'react';
import moment from 'moment';
import { App } from '@meronex/app';
import AnimateHeight from 'react-animate-height';
import Grid from '@material-ui/core/Grid';
import { IconButton } from '@material-ui/core';

import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import IReadOnly from '@meronex/icons/bi/BiBookReader';
import ActiveStepInfo from '../../products/steps/ActiveStepInfo';

import { Icons } from '../../../../common/Icons';
import CircularProgress from '../../../../common/CircularProgress';
import SitesAvatar from '../../../../common/SitesAvatar';
import SitesUserName from '../../../../common/SitesUserName';

import { p, can } from '../../../../common/roles';

const WorkOrderDraft = (props) => {
  const { workOrder, index, onResume, onDelete, onClick } = props;
  const [expanded, setExpanded] = React.useState();

  console.log(workOrder);
  const renderProgress = (progress) => {
    return <CircularProgress value={progress} />;
  };
  const renderTime = (timestamp) => {
    return moment(timestamp).format('DD/MM/YYYY hh:mm:ss A');
  };

  const _onDelete = () => {
    console.log('on remove draft');
    setExpanded(!expanded);
    onDelete(workOrder);
  };
  const _onResume = () => {
    console.log('on resume draft');
    setExpanded(!expanded);
    onResume(workOrder);
  };

  console.log(workOrder);
  let disableDelete = false;
  let readOnly = false;

  const user = App.getUser();
  if (
    user &&
    workOrder.draft &&
    workOrder.draft.createdBy &&
    workOrder.draft.createdBy._id
  ) {
    if (workOrder.draft.createdBy._id !== user._id) {
      if (!can(p.factories.deleteOthersWorkOrder)) {
        disableDelete = true;
      }
      if (!can(p.factories.modifyOthersWorkOrder)) {
        readOnly = true;
      }
    }
  }

  return (
    <div
      style={{
        cursor: 'pointer',
        padding: '5px',
        minHeight: '70px',
        borderTop: '1px solid rgb(239 239 239)',
        backgroundColor: index % 2 === 0 ? 'rgb(249 249 249)' : '',
      }}>
      <div
        onClick={() => {
          console.log('on draft click');

          if (can(p.factories.CRUDWorkOrders)) {
            setExpanded(!expanded);
          }
          if (onClick) onClick(workOrder);
        }}>
        <div
          style={{
            marginBottom: '15px',
            paddingTop: '10px',
          }}>
          <div>
            <Grid container>
              <Grid item xs={2}>
                <SitesAvatar
                  name={workOrder.product.name}
                  image={(workOrder.product.image || {}).thumbUrl}
                  size={50}
                  round={5}
                />
              </Grid>
              <Grid item xs={8}>
                <div
                  style={{
                    marginLeft: '2px',
                    position: 'relative',
                    fontSize: '15px',
                    fontWeight: '400',
                  }}>
                  <Truncate width={250}>{workOrder.product.name}</Truncate>
                  <div
                    style={{
                      fontSize: '12px',
                      color: 'gray',
                    }}>
                    <Icons.Code /> {workOrder.product.code}
                  </div>
                </div>
              </Grid>
              <Grid item xs={2}>
                {workOrder.seqNum && (
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}>
                    #{workOrder.seqNum}
                    <div>{workOrder.batchNum}</div>
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
          <Grid conatiner style={{ display: 'flex', marginTop: '5px' }}>
            <Grid item xs={10}>
              <div
                style={{
                  fontSize: '13px',
                  marginTop: '15px',
                }}>
                <div>
                  Created {renderTime(workOrder.draft.createdAt)} (
                  {App.utils.time.timeAgo(workOrder.draft.createdAt)})
                </div>
                <div>
                  By <SitesUserName profile={workOrder.draft.createdBy} />
                </div>
                <div>
                  Last update{' '}
                  {App.utils.time.timeAgo(workOrder.draft.updatedAt)}
                </div>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '20px',
                }}>
                {renderProgress(workOrder.draft.progress)}
              </div>
            </Grid>
          </Grid>
        </div>
        <ActiveStepInfo workOrder={workOrder} />
      </div>
      <div>
        <AnimateHeight
          duration={200}
          style={{
            width: '100%',
          }}
          height={expanded ? 90 : 0} // see props documentation below
        >
          <div>
            <Grid
              container
              style={{
                textAlign: 'center',
                marginTop: '20px',
              }}>
              <Grid
                item
                xs={!disableDelete ? 6 : 12}
                onClick={() => {
                  _onResume();
                }}>
                {readOnly && (
                  <>
                    <>
                      <div>
                        <IconButton>
                          <IReadOnly color={'#556cd6'} />
                        </IconButton>
                      </div>
                      <div
                        style={{
                          color: '#556cd6',
                        }}>
                        View Read Only
                      </div>
                      }
                    </>
                  </>
                )}

                {!readOnly && (
                  <>
                    <div>
                      <IconButton>
                        <Icons.Resume color={'#556cd6'} />
                      </IconButton>
                    </div>
                    <div
                      style={{
                        color: '#556cd6',
                      }}>
                      Resume
                    </div>
                    }
                  </>
                )}
              </Grid>

              {!disableDelete && (
                <Grid
                  item
                  xs={6}
                  onClick={() => {
                    _onDelete();
                  }}>
                  <div>
                    <IconButton>
                      <Icons.Remove color={'red'} />
                    </IconButton>
                  </div>
                  <div
                    style={{
                      color: 'red',
                    }}>
                    Delete
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
        </AnimateHeight>
      </div>
    </div>
  );
};
WorkOrderDraft.propTypes = {
  onResume: PropTypes.func,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
};

WorkOrderDraft.defaultProps = {
  onResume: () => {},
  onDelete: () => {},
  onClick: () => {},
};
export default WorkOrderDraft;
