import React from 'react';
import PropTypes from 'prop-types';

import AnimateHeight from 'react-animate-height';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import LocalizationProvider from '@material-ui/pickers/LocalizationProvider';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { MobileDateRangePicker } from '@material-ui/pickers';

import { App } from '@meronex/app';
import { ActionBtn } from '@meronex/components';

import IFilterOutline from '@meronex/icons/mdc/MdcFilterMenuOutline';
import AiOutlineSearch from '@meronex/icons/ai/AiOutlineSearch';

import { Icons } from '../common/Icons';
import QrCodeScanDialog from './QRCodeScanDialog';
import DateFnsUtils from '@date-io/date-fns';

const Filter = React.forwardRef((props, ref) => {
  const {
    onFilter,
    onApply,
    onReset,
    placeholder,
    style,
    expandable,
    enableDateRange,
    height,
    borderRadius,
    filterOnApply,
    enableQrCode,
    autoExpand,
  } = props;

  const initData = {
    dateType: '',
    range: [null, null],
    filterValue: '',
  };
  const [expanded, setExpanded] = React.useState(false);
  const [data, setData] = React.useState(initData);
  const [applied, setApplied] = React.useState();
  const [openQrCodeDialog, setOpenQrCodeDialog] = React.useState();
  const [qrCodeData, setQrCodeData] = React.useState();

  const mergedStyle = Object.assign(
    {
      marginTop: '-12px',
    },
    style
  );

  React.useImperativeHandle(ref, () => ({
    reset: () => {
      console.log('reset called');
      _onReset();
    },
  }));

  const onEventResetFilter = () => {
    console.log('on reset filter event');
    _onReset();
  };
  React.useEffect(() => {
    App.eventsManager.on('event-reset-filter', Filter.name, onEventResetFilter);
    return () => {
      App.eventsManager.removeListener(
        'event-reset-filter',
        Filter.name,
        onEventResetFilter
      );
    };
  }, []);

  React.useEffect(() => {
    console.log('qr code data');
    if (qrCodeData) {
      _onFilter(qrCodeData);
    }
  }, [qrCodeData]);

  const hasFilter = () => {
    let _hasFilter = false;
    const { range, filterValue, dateType } = data;
    if (range[0] || range[1]) {
      _hasFilter = true;
    }
    if (filterValue) {
      _hasFilter = true;
    }
    if (dateType) {
      _hasFilter = true;
    }
    return _hasFilter;
  };
  const getHeight = () => {
    let _height = 0;
    if (expanded) {
      _height += height;
      if (data.dateType === 'range') {
        _height += 100;
      }
    }

    return _height;
  };

  const _onApply = () => {
    console.log('on apply');
    setApplied(true);
    onApply(data);
    setExpanded(false);
  };

  const _onFilter = (value) => {
    console.log('on filter');
    if (!filterOnApply) {
      onFilter(value);
      setApplied(true);
      if (autoExpand) {
        setExpanded(true);
      }
    } else {
      setExpanded(true);
    }
  };
  const _onReset = () => {
    setData(initData);
    onReset();
    setExpanded(false);
    setApplied(false);
  };

  const _onQrCodeScan = (v) => {
    if (v && qrCodeData !== v) {
      setQrCodeData(v);
      const _data = { ...data };
      _data.filterValue = v;
      setData(_data);
      setOpenQrCodeDialog(false);
    }
  };

  const inputProps = {
    style: {
      borderRadius,
      height: '50px',
    },
    startAdornment: (
      <InputAdornment position="start">
        <IconButton edge="start">
          <AiOutlineSearch />
        </IconButton>
      </InputAdornment>
    ),
  };

  if (enableQrCode) {
    inputProps.endAdornment = (
      <InputAdornment position="start">
        <div
          onClick={() => {
            setOpenQrCodeDialog(true);
          }}>
          <IconButton
            style={{
              height: '70px',
              width: '70px',
              zIndex: 11,
            }}
            edge="start"
            onClick={() => {
              setQrCodeData(null);
              setOpenQrCodeDialog(true);
            }}>
            <Icons.QrCode />
          </IconButton>
        </div>
      </InputAdornment>
    );
  }
  return (
    <div style={mergedStyle}>
      <Grid
        container
        spacing={1}
        style={{
          marginRight: '10px',
        }}>
        <Grid item xs={expandable ? 10 : 12}>
          <TextField
            fullWidth
            style={{
              height: '50px',
            }}
            variant={'outlined'}
            placeholder={placeholder}
            value={data.filterValue}
            margin="normal"
            InputProps={inputProps}
            onChange={(e) => {
              const _data = { ...data };
              _data.filterValue = e.target.value;
              setData(_data);
              _onFilter(e.target.value);
            }}
            variant="outlined"
          />
        </Grid>
        {expandable && (
          <Grid item xs={2}>
            <IconButton
              onClick={() => {
                setExpanded(!expanded);
              }}
              style={{
                position: 'relative',
                top: '16px',
                height: '50px',
                color: '#6a6a6a',
                borderRadius: '5px',
                backgroundColor: applied ? '#43c16a29' : 'inherit',
                border: '1px solid #c4c4c4',
                width: '100%',
              }}>
              <IFilterOutline size={18} />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <AnimateHeight duration={200} height={getHeight()}>
        {enableDateRange && (
          <div>
            <div
              style={{
                marginTop: '10px',
              }}>
              <TextField
                id="select"
                label="Date"
                value={data.dateType}
                InputProps={{
                  style: {
                    borderRadius: '4px',
                    height: '50px',
                  },
                }}
                onChange={(e) => {
                  const _data = { ...data };
                  _data.dateType = e.target.value;
                  setData(_data);
                }}
                select
                fullWidth
                variant={'outlined'}>
                <MenuItem value="today">Today</MenuItem>
                <MenuItem value="yesterday">Yesterday</MenuItem>
                <MenuItem value="thisWeek">This week</MenuItem>
                <MenuItem value="range">Date Range</MenuItem>
              </TextField>
            </div>
            {data.dateType === 'range' && (
              <div
                style={{
                  marginTop: '20px',
                }}>
                <LocalizationProvider dateAdapter={DateFnsUtils}>
                  <div
                    style={{
                      width: '100%',
                    }}>
                    <MuiThemeProvider>
                      <MobileDateRangePicker
                        renderInput={(startProps, endProps) => {
                          startProps.helperText = null;
                          endProps.helperText = null;
                          return (
                            <>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <TextField
                                    fullWidth
                                    style={{ color: 'white' }}
                                    variant="outlined"
                                    {...startProps}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    fullWidth
                                    style={{ color: 'white' }}
                                    variant="outlined"
                                    {...endProps}
                                  />
                                </Grid>
                              </Grid>
                            </>
                          );
                        }}
                        // InputProps={{ className: classes.input }}
                        startText="Start Date"
                        endText="End Date"
                        helperText={null}
                        value={data.range}
                        onChange={(range) => {
                          const _data = { ...data };
                          _data.range = range;
                          setData(_data);
                        }}
                      />
                    </MuiThemeProvider>
                  </div>
                </LocalizationProvider>
              </div>
            )}

            <div
              style={{
                marginBottom: '20px',
                textAlign: 'center',
                marginTop: '20px',
              }}>
              <ActionBtn label={'Apply'} onClick={_onApply} />
              <div
                onClick={_onReset}
                style={{
                  marginTop: '30px',
                  cursor: 'pointer',
                  fontSize: '14px',
                }}>
                Reset
              </div>
            </div>
          </div>
        )}
      </AnimateHeight>
      {enableQrCode && (
        <QrCodeScanDialog
          open={openQrCodeDialog}
          onScan={_onQrCodeScan}
          onClose={() => {
            setOpenQrCodeDialog(false);
          }}
        />
      )}
    </div>
  );
});
Filter.propTypes = {
  onFilter: PropTypes.func,
  filterOnApply: PropTypes.bool,
  filterValue: PropTypes.string,
  onApply: PropTypes.func,
  onReset: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  expandable: PropTypes.bool,
  autoExpand: PropTypes.bool,
  enableDateRange: PropTypes.bool,
  enableQrCode: PropTypes.bool,
  borderRadius: PropTypes.string,
  extraFields: PropTypes.node,
};
Filter.defaultProps = {
  expandable: false,
  enableDateRange: false,
  filterOnApply: false,
  height: 200,
  borderRadius: '5px',
  enableQrCode: false,
  autoExpand: false,
  onApply: (v) => {},
  onReset: (v) => {},
  onFilter: (v) => {},
};
export default Filter;
