import React from 'react';
import PropTypes from 'prop-types';

import { ActionBtn, CloseBtn, toast } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import { rolesMng } from '../api/rolesMng';
import RoleForm from './RoleForm';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function AddRoleDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, onAdd = () => {} } = props;

  const [loading, setLoading] = React.useState(false);
  const [role, setRole] = React.useState();

  const createRole = async () => {
    setLoading(true);
    const _role = { ...role };
    try {
      await rolesMng.addRole(_role);
      onAdd();
      _onClose();
      toast.success('Role added successfully', {
        autoClose: 900,
      });
    } catch (e) {
      toast.error('Ops, something went wrong');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const _onClose = () => {
    setRole({});
    onClose();
  };

  if (!open) {
    return null;
  }

  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        Add Role
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent>
        <RoleForm
          role={{}}
          onChange={(data) => {
            console.log(data);
            setRole({ ...data });
          }}
        />
        <div
          style={{
            marginBottom: '20px',
            marginTop: '10px',
            textAlign: 'center',
          }}>
          <ActionBtn
            loading={loading}
            disabled={loading || !role || !role.name}
            label={<span>Add Role</span>}
            onClick={createRole}
            onEnter={createRole}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

AddRoleDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAdd: PropTypes.func,
};

AddRoleDialog.defaultProps = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAdd: () => {},
};

export default AddRoleDialog;
