import React from 'react';
import PropTypes from 'prop-types';
import { App } from '@meronex/app';
import { Loading } from '@meronex/components';
import MdArrowBack from '@meronex/icons/md/MdArrowBack';
import 'react-notion/src/styles.css';
import { NotionRenderer } from 'react-notion';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import MdSystemUpdateAlt from '@meronex/icons/md/MdSystemUpdateAlt';
import Fade from '@material-ui/core/Fade';
import Confetti from 'react-confetti';
const { server } = App;

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: 'rgb(14, 14, 14)',
    height: '60px',
    color: theme.appBar.color,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChangelogDialog = (props) => {
  const { open, onClose } = props;
  const [changelog, setChangelog] = React.useState(undefined);
  const [updating, setUpdating] = React.useState(false);
  const classes = useStyles();

  const fetchChangelog = async () => {
    const { settings } = App;

    setChangelog(null);
    console.log('loadong..');
    try {
      // calling twice to bypass splitbee cache
      fetch(settings.changelog?.notionSrc).then((res) => {
        setTimeout(() => {
          fetch(settings.changelog?.notionSrc).then((res) => {
            res.json().then((data) => setChangelog(data));
          });
        }, 500);
      });
    } catch (e) {}
  };
  const update = () => {
    setUpdating(true);
    setTimeout(() => {
      App.forceReload();
    }, 500);
  };
  React.useEffect(() => {
    fetchChangelog();
  }, [open]);

  return (
    <Dialog
      fullScreen={!!App.utils.isMobile()}
      fullWidth
      maxWidth={'md'}
      className={'change-log-dialog'}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              onClose();
            }}
            aria-label="close">
            <MdArrowBack />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Changelog
          </Typography>
          <div onClick={update} style={{ cursor: 'pointer' }}>
            Update{' '}
            <span style={{ position: 'relative', top: '2px' }}>
              <MdSystemUpdateAlt />
            </span>
          </div>
        </Toolbar>
      </AppBar>
      <div
        style={{
          overflow: 'scroll',
          padding: '10px',
          backgroundColor: 'white',
        }}>
        {!changelog && <Loading />}
        {changelog && <NotionRenderer blockMap={changelog} />}
      </div>
      <div
        style={{
          display: updating ? 'inline' : 'none',
        }}>
        <Fade in={updating}>
          <Confetti numberOfPieces={50} gravity={0.1} tweenDuration={1200} />
        </Fade>
      </div>
    </Dialog>
  );
};

ChangelogDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

ChangelogDialog.defaultProps = {
  onClose: () => {},
  open: false,
};

export default ChangelogDialog;
