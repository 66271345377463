import React from 'react';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';

import { initApp } from '@meronex/app';

import './modules/sitesHub.css';
(async () => {
  const App = await initApp({
    endpoint: 'wss://sites.meronex.com/websocket',
    toastPosition: toast.POSITION.BOTTOM_RIGHT,
    defaultLanguage: 'en',
    themeType: 'light',
    translations: undefined,
  });

  const IndexContainer = require('@meronex/components').IndexContainer;
  const AppRoutes = require('@meronex/layout').AppRoutes;
  const theme = require('./theme').default;
  const config = require('./config').config;

  const routes = require('./config/routes').routes;

  document.getElementById('loader').remove();
  document.removeEventListener(
    'visibilitychange',
    window.loadingVisibilityChange
  );
  clearTimeout(window.loadingTimeout);

  window.addEventListener('load', function () {
    window.history.pushState({}, '');
  });

  window.addEventListener('popstate', function () {
    window.history.pushState({}, '');
  });
  window.history.pushState(null, null, window.location.href);
  window.history.back();
  window.history.forward();
  const renderIndex = () => {
    return (
      <IndexContainer App={App} theme={theme} config={config}>
        <AppRoutes routes={routes} />
      </IndexContainer>
    );
  };
  ReactDOM.render(renderIndex(), document.querySelector('#root'));
})();
