import { App } from '@meronex/app';

const { server } = App;

export const rolesMng = {
  addRole: async (siteRole) => {
    const result = await server.call('addSiteRole', { siteRole });
    return result;
  },
  updateRole: async (siteRole) => {
    const result = await server.call('updateSiteRole', { siteRole });
    return result;
  },
  getRoles: async () => {
    const result = await server.call('getSiteRoles', {});
    console.log(result);
    return result;
  },
  deleteRole: async (role) => {
    console.log(`delete role, id: ${role._id}`);
    const result = await server.call('deleteSiteRole', { _id: role._id });
    return result;
  },
};
