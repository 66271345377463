import React from 'react';
import PropTypes from 'prop-types';

import { App } from '@meronex/app';

import { toast, ToastContainer } from 'react-toastify';
import PWAPrompt from 'react-ios-pwa-prompt';

import { HeaderBar } from '@meronex/layout';
import IConvert from '@meronex/icons/mdc/MdcTapeMeasure';
import Grid from '@material-ui/core/Grid';

import View from './components/View';
import UnitConverter from './customViews/unitConverter/UnitConverter';

import Home from '../home/Home';
import SideMenu from './components/SideMenu';

import SettingsView from '../settings/SettingsView';
import EquipmentsView from '../equipments/EquipmentsView';

import StoresView from '../stores/StoresView';
import CalculatorView from './customViews/CalculatorView';
import NewsView from './customViews/news/NewsView';

import FactoriesView from '../factories/FactoriesView';
import LogsView from '../logs/LogsView';
import IssuesView from '../issues/IssuesView';

const queries = {
  mobile: { maxWidth: 600 },
  tablet: { width: '600..1024' },
  desktop: { minWidth: 1024 },
  printer: { type: 'print' },
  blackAndWhiteScreen: { type: 'screen', monochrome: 1 },
};

function ViewsContainer(props) {
  const [activeView, setActiveView] = React.useState('home');

  const onActiveViewEvent = (e) => {
    setActiveView(e.activeView);
  };
  React.useEffect(() => {
    App.eventsManager.on(
      'event-active-view',
      ViewsContainer.name,
      onActiveViewEvent
    );
    return () => {
      App.eventsManager.removeListener(
        'event-active-view',
        ViewsContainer.name,
        onActiveViewEvent
      );
    };
  }, []);

  const onWorkOrderAdded = () => {
    App.eventsManager.emit('event-work-order-added', ViewsContainer.name);
  };

  const bodyStyle = {};

  return (
    <div>
      <div className={`${activeView === 'home' ? 'home' : ''} header-bar`}>
        <HeaderBar config={App.config.header} />
      </div>
      <Grid container>
        <Grid item xs={2} className={'side-menu'}>
          <div
            style={{
              position: 'absolute',
              top: '100px',
              left: '20px',
            }}>
            <SideMenu activeView={activeView} />
          </div>
        </Grid>
        <Grid item xs={12} lg={10} style={bodyStyle}>
          <Home activeView={activeView} />
          <StoresView activeView={activeView} />
          <FactoriesView
            activeView={activeView}
            viewId={'factories'}
            onWorkOrderAdded={onWorkOrderAdded}
          />
          <EquipmentsView activeView={activeView} />
          <SettingsView activeView={activeView} />
          <IssuesView activeView={activeView} />
          <LogsView activeView={activeView} />
          <View
            viewId={'unitsConverter'}
            activeView={activeView}
            title={'Units Converter'}
            icon={<IConvert size={20} />}
            showMenu>
            <UnitConverter />
          </View>
          {/*<DrawView activeView={activeView} />*/}
        </Grid>
      </Grid>
      <PWAPrompt
        promptOnVisit={1}
        timesToShow={7}
        delay={5000}
        permanentlyHideOnDismiss={false}
      />
      <ToastContainer
        autoClose={800}
        position={App.getToastPosition() || toast.POSITION.BOTTOM_CENTER}
      />
    </div>
  );
}
ViewsContainer.propTypes = {
  showHeader: PropTypes.bool,
};

ViewsContainer.defaultProps = {
  showHeader: true,
};

export default ViewsContainer;
