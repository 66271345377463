import React from 'react';
import PropTypes from 'prop-types';

import { App } from '@meronex/app';

import { ActionBtn, CloseBtn, toast } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import RoleForm from './RoleForm';
import { rolesMng } from '../api/rolesMng';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function EditRoleDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, onEdit } = props;

  const [loading, setLoading] = React.useState(false);
  const [role, setRole] = React.useState(props.role || {});

  const _onClose = () => {
    onClose();
  };

  const editRole = async () => {
    console.log('on edit');
    console.log(role);

    setLoading(true);

    try {
      await rolesMng.updateRole(role);
      onEdit();
      onClose();
      toast.success('Role updated', {
        autoClose: 900,
      });
    } catch (e) {
      toast.error('Ops, something went wrong');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  if (!role || !open) {
    return null;
  }
  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        Edit Role
        <CloseBtn onClick={_onClose} />
      </DialogTitle>
      <DialogContent>
        <RoleForm
          onChange={(role) => {
            console.log(role);
            setRole(role);
          }}
          role={role}
        />
        <div
          style={{
            marginBottom: '20px',
            marginTop: '20px',
            textAlign: 'center',
          }}>
          <ActionBtn
            loading={loading}
            disabled={loading || (role && !role.name)}
            label={<span>Save Role</span>}
            onClick={editRole}
            onEnter={editRole}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

EditRoleDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  editRole: PropTypes.func,
  role: PropTypes.object,
  onEdit: PropTypes.func,
};

EditRoleDialog.defaultProps = {
  open: false,
  onClose: () => {},
  role: {},
};

export default EditRoleDialog;
